.letter-header{
    padding: 21px 0px 0px 17px;
    // position: sticky;
    // top: 0;
    background-color: #ffffff;
    // z-index: 99;
    // height: 60px;
    // border-bottom: 1px solid
    .ilmiya-logo{
        width: 40px;
        height: 40px;


    }

}
.letter-footer{
    text-align: center;
    border-top: 1px solid #DEE1E6;
    // position: sticky;
    // bottom: 0px;
    // width: 100%;
    padding: 38px 58px;
    background-color: #ffffff;
    // z-index: 10;
    // max-height: 243px;
    .footer-logo{
        img{
            max-width: 126px;
            width:100% ;
            height: 33px;
        }
    }
    p{
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #2D2F31;
        margin: 20px 0px;
        span{
            a{
                 color: #2D2F31; ;
            }
        }

    }
    .footer-links{
        ul{
            list-style-type: none;
            display: flex;
            justify-content: center;
            gap: 39px;
            // align-items: center;
            li{
                a{
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    color: #2181FA;
                    text-decoration: none;
                }

            }
        }
    }
    .footer-social-icon{
        display: flex;
        justify-content: center;
        color: #525252;
        gap: 24px;
        a{
            text-decoration: none;
            svg{
                width: 18px;
                height: 18px;
                color: #525252;
            }
        }
    }
}

.letter-page{
    .letter-para{
        padding: 65px 0px 71px 0px;
        text-align: center;
        // position: relative;
        .big-img{
            text-align: center;
            position: relative;
            img{
                max-width: 352px;
                width: 100%;
                height: 352px;
                object-fit: contain;
            }
            .banner-img{
                width: 132px;
                height: 132px;
                object-fit: contain;
                position: absolute;
                top: 100px;
                left: 50%;
                transform: translate(-45%);
            }

        }
        .letter-small-img{
            text-align: center;
            img{
                max-width:182px ;
                width: 100%;
                height: 158px;
                object-fit: contain;
            }
            span{
                display: block;
                color: #404040;
                font-size: 18px;
                font-weight: 600;
                line-height: 19px;
                margin-top: 17px;
            }
        }
        .letter-top{
            position: relative;
            margin-bottom: 51px;
        }
        h2{
            font-size: 42px;
            font-weight:600 ;
            line-height: 57px;
            color: #2D2F31;
            text-align: center;
            // margin: 60px 0px;
            &.thanks-header{
                margin: 24px 0px;
            }
            &.letter-welcome-header{
                margin: 60px 0px;

            }
            span{
                background: linear-gradient(to right, #ff7e5f, #69B7FA); /* Gradient colors */
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-fill-color: transparent;
            }
            &.top{
                position: absolute;
                left: 50%;
             transform: translate(-50%);
            bottom: -55px;
            }
        }
    
       p{
        font-size: 16px;
        font-weight:400 ;
        line-height:28px ;
        color: #2D2F31;
        text-align: left;
        &.para-align{
            text-align: center;
        }
       }
       h4{
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        text-align: center;
        margin: 76px 0px 24px 0px;
       }
       button{
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #0081FA;
        border: none;
        border-radius: 8px;
        padding: 16px 32px;
        margin-top: 40px;
        text-align: center;
        
       }
       .invite-btn{
        background: linear-gradient(to right, #7C4ACF, #69B7FA); 
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;

        
    }
    }

}
.welcome-letter-page{
    .letter-para{
        ul{
            text-align: left;
        }
        ol{
            text-align: left;
        }

    }
   
}


// welcome header
.welcome-div{
    border-bottom: 1px solid #DEE1E6;
    .welcome-header{
        padding: 12px 30px;
        // border-bottom: 1px solid #DEE1E6;
        a{
            text-decoration: none;
            img{
                width: 36px;
                height: 36px;
                object-fit: contain;
            }
            span{
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                color: #2D2F31;
                margin-left: 14px;
            }
        }
    
    }

}
// .welcome-header{
//     padding: 12px 30px;
//     border-bottom: 1px solid #DEE1E6;
//     a{
//         text-decoration: none;
//         img{
//             width: 36px;
//             height: 36px;
//             object-fit: contain;
//         }
//         span{
//             font-size: 14px;
//             font-weight: 600;
//             line-height: 21px;
//             color: #2D2F31;
//             margin-left: 14px;
//         }
//     }

// }

.signup-welcome-page{
    .signup-para{
        text-align: center;
        padding: 42px 91px 84px 91px;
        .object-img{
            max-width: 225px;
            width: 100%;
            height: 102px;
            object-fit: contain;

        }
        .verify-img{
            max-width: 221px;
            width: 100%;
            height: 144px;
            object-fit: contain;

        }
        .img-border{
            width: 133px;
            height: 133px;
            border:5px solid  #2181FA;
            border-radius: 50%;
            margin: 0 auto;

        }
        .person-img{
            width: 123px;
            height: 123px;
            object-fit: contain;
        }
        h3{
            font-size: 32px;
            font-weight: 400;
            line-height: 48px;
            text-align: center;
            color: #000000;
            margin-top: 20px;
        }
        span{
            a{
                text-decoration: none;
                    color: #000000;
                    margin-top: 5px;
                    font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                       text-align: center;
            }
        }
        
        .text-div{
            max-width: 458px;
            width: 100%;
            display: flex;
            flex-direction: column;
            // align-items: center;
            margin: 0 auto;
        }
        
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            // margin-top: 85px;
            &.valid-date{
                font-size: 10px;
                line-height: 18px;
                text-align: center;
                // margin-top: 40px;
            }
            &.verify-margin{
                margin-top: 52px;

            }
            &.signup-margin{
                margin-top: 85px;
            }
            &.organization-margin{
                margin-top: 50px;
            }
           

            span{
            font-size: 20px;
            font-weight: 600;
            line-height: 36px;
            // text-align: left;
            display: inline-block;
            }
           

        }
        .span-btn{
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            align-items: center;
            color: #FFFFFF;
            background-color: #2181FA;
            padding: 16px 38px;
            border: none;
            border-radius: 7px;
            max-width: 240px;
            width: 100%;
            
            &.member-margin{
                margin:40px 0px  10px 0px;
    
            }
            &.verify-btn{
                margin-bottom: 30px;

            }
        }
      
      

    }

}


