@import "./pages.scss";
// @import "./Edcloud.scss";
@import "./LetterPage.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$white: #ffffff;
$black: #000000;
$text-color: #404040;
$poppins: "Poppins";
$primary01: #3b82f6;
$border: #dee1e6;
$heading-color:#2D2F31;

//***************************************** Dark Mode Scss *****************************
$darkBg: #1d1d1d;
$darkBorder: #444746;
$lightDarkBg: #242526;
$light_white_text: #e3e3e3;
$light_white_img_color: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(5437%) hue-rotate(83deg) brightness(88%) contrast(102%);

*,
body {
  font-family: $poppins !important;
}

.dark-theme {
  background-color: $darkBg;
  background: $darkBg;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  a,
  strong,
  ul li,
  ul li a,
  label,
  select,
  th,
  td {
    color: $light_white_text !important;
  }

  header.header {
    background: $darkBg;
    border-bottom: 1px solid $darkBorder;

    ul.menu {
      li {
        &.bordered {
          border: 1px solid $darkBorder;
        }

        &.borderd {
          border: 1px solid $darkBorder;
        }

        >img {
          filter: $light_white_img_color;

          &.filter-none {
            filter: none !important;
          }
        }

        .dropdown-toggle {
          filter: $light_white_img_color;
        }
      }
    }

    .dropdown-menu {
      background-color: $lightDarkBg !important;

      .dropdown-item {
        &:hover {
          .single-item{
            a{
              color: #2181fa !important;
            }
            .icon{
            img,
          svg {
            filter: brightness(0) saturate(100%) invert(42%) sepia(38%) saturate(3869%) hue-rotate(198deg) brightness(97%) contrast(102%) !important; 
          }
          img.no-filter{
            filter: none !important;
          }
        }
          }
        }
      }

      .icon {
        background: $lightDarkBg !important;
      }

      a {
        color: $light_white_text !important;

        .icon-wrapper {
          background: $lightDarkBg !important;

          img,
          svg {
            filter: $light_white_img_color !important;

            &.no-filter {
              filter: none !important;
            }
          }
        }
      }
    }

    .header-modal {
      background-color: $darkBg !important;

      ul {
        li {
          &:hover {
            background: $lightDarkBg !important;
          }

          a {
            color: $light_white_text !important;

            .icon-wrapper {
              background: $lightDarkBg !important;

              img,
              svg {
                filter: $light_white_img_color !important;
              }
            }
          }
        }
      }
    }
  }

  .page-breadcrumb {
    border-bottom: 1px solid $darkBorder !important;

    strong{
      a{
        img{
          filter: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(5437%) hue-rotate(83deg) brightness(88%) contrast(102%);
        }
      }
    }
  }

  .legal-page-wrapper {
    .legal-page-content-wrapper {
  .table-title {
    .icon {
      img {
        filter: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(5437%) hue-rotate(83deg) brightness(88%) contrast(102%);
      }
    }
  }
}
}

  .page-content-wrapper{
    .page-content{
    background: $darkBg;
  }
}

.contact-us-page-new .contact-us-form-wrapper .contact-us-form .input-fields .input-wrapper{
.students-dropdown{
  .dropdown-toggle{
    img{
      filter: $light_white_img_color;
    }
  }
.dropdown-menu {
  background-color: $lightDarkBg !important;

  .dropdown-item{
    &:hover{
      background-color: $darkBg
    }
  }
}
}
}

.legal-details-page-wrappper { 
  .page-content-wrapper { 
  .page-content { 
  .top-image-wrapper {
    background-color: $darkBg;
} 
}
}
}
.page-content-section{
.page-content-container{
.privacy-content-section{
.privacy-content-accordion{
.accordion{
.accordion-collapse{
.accordion-body{
  a{
    h2{
    &:hover{
      background-color: #2d2f34 !important;
    }
  }
  }
.disc-style-ul,.circle-style-ul{
  li{
    &:hover{
      background-color: #2d2f34 !important;
    }
    a{
      &:hover{
        background-color: transparent !important;
      }
    }
  }
}
}
}
}
}
}
}
}

.subprocessor-table-wrapper{

  &::-webkit-scrollbar-thumb {
    background: #2f3237 !important;
  }


  table{
  background-color: $darkBg !important;

  tbody{
    tr{
      td{
    border-left: 1px solid #2f3237 !important;
    border-top: 1px solid #2f3237 !important;
        a{
          color: #b6d0f7 !important;
        }

        &:first-child {
          border-left: 1px solid #2f3237 !important;
        }

        &:last-child {
          border-right: 1px solid #2f3237 !important;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #2f3237 !important;
        }
      }
    }
  }

  thead {
    tr {
      th {
        border-left: 1px solid #2f3237 !important;
    border-top: 1px solid #2f3237 !important;
        &:last-child {
          border-right: 1px solid #2f3237 !important;
        }
      }
    }
  }

  // tbody {
  //   tr {
  //     td {
  //       &:first-child {
  //         border-left: 1px solid #ddd;
  //       }

  //       &:last-child {
  //         border-right: 1px solid #ddd;
  //       }
  //     }

  //     &:last-child {
  //       td {
  //         border-bottom: 1px solid #ddd;
  //       }
  //     }
  //   }
  // }
}
}

  .brand-dropdown-wrapper {
    .brand-dropdown {
      .dropdown-menu {
        background: $lightDarkBg;
        border: $darkBorder;

        .dropdown-item {
          &:hover {
            background-color: $darkBg !important;
          }

          .single-item {
            .icon {
              background: $darkBg;

              img,
              svg {
                filter: $light_white_img_color !important;
              }
            }
          }
        }
      }
    }
  }

  .router-page-wrapper {
    .router-page-form {
      .form-wrapper {
        background: $lightDarkBg;

        .form-input-section {
          .input-section {
            .domain {
              background-color: #444746;
            }
          }

        }

      }

      .error-section {
        p {
          color: #1d1d1d !important;
        }
      }
    }
  }

  .thank-you-page-wrapper {
    .page-content-wrapper {
      .page-content {
        .page-inner {
          background-color: #242526;

          .check-icon {
            background-color: #242526;
            border-radius: 32px;
            // box-shadow: 0 0 20px 5px #7C4ACF,   /* Purple at 0% */
            // 0 0 0px 10px #69B7FA,  /* Light blue at 38% */
            // 0 0 0px 15px #FFA96E,  /* Orange at 76% */
            // 0 0 0px 20px #FE506F;
          }

          .content-section {
            .title {
              h1 {
                color: $light_white_text;
              }
            }
          }
        }
      }
    }
  }

  .footer-bottom-bar {
    background: $darkBg;
    border-top: 1px solid $darkBorder;

    ul.bottom-menu {
      li {
        a {
          color: $light_white_text !important;
        }
      }
    }

    .social-items-wrapper {
      ul.bottom-menu {
        li {
          a {

            img,
            svg {
              filter: $light_white_img_color;
            }
          }
        }
      }
    }
  }

  .home-page {
    // background-image: url('/home-dark-bg-img.png');
  }

  .explore-btn,
  .single-option {
    a {
      color: $light_white_text !important;
      background-color: #444746 !important;

      &.getting-started {
        background: transparent !important;
      }

      img,
      svg {
        filter: $light_white_img_color;
      }
    }
  }

  .single-card {
    background-color: $lightDarkBg !important;
    border: 1px solid $darkBorder !important;

    .icon {
      background-color: $lightDarkBg !important;
      border: 1px solid $darkBorder !important;
    }
  }

  .contact-us-page-new {
    .contactus-accordions-wrapper {
      .contactus-accordion {
        .accordion-item {
          background: $lightDarkBg !important;
          border: none !important;

          .accordion-body {
            color: $light_white_text !important;
          }
        }
      }
    }

    .contact-us-form-wrapper {
      background: $lightDarkBg !important;
      border: 1px solid $darkBorder;

      .css-mya99o-control,
      .css-1nmdiq5-menu,
      .css-81esw1-control {
        background: $lightDarkBg !important;

        div,
        a {
          &:hover {
            background-color: transparent !important;
            background: transparent !important;
          }
        }
      }

      .contact-us-form {
        .input-fields {
          .single-field {
            label {
              color: $light_white_text !important;
            }

            textarea,
            input {
              border-color: $darkBorder;
              color: #e3e3e3;

              // background:$lightDarkBg !important;
              &.dark-bg-input {
                background: $lightDarkBg !important;
              }

              &::placeholder {
                color: #c3c3c3 !important;
              }
            }

            .input-wrapper {

              select,
              input {
                border: 1px solid $darkBorder;

                // background:$lightDarkBg !important;
                &:focus {
                  border: 1px solid #7949ff;
                }
              }
            }
          }

          .input-wrapper {

            input,
            textarea {
              border: 1px solid $darkBorder; // Border color for dark theme
            }

            select {
              width: 100%;
              padding: 14.5px 12px;
              background-color: #242526; // Background color for dark theme
              color: #fff; // Text color for dark theme
              border: 1px solid $darkBorder; // Border color for dark theme
              border-radius: 4px;
              appearance: none; // Remove default dropdown arrow
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: none;
              cursor: pointer;
              transition: background-color 0.3s ease, border-color 0.3s ease;

              &:focus {
                // border-color: #007bff; // Border color on focus
                background-color: #242526; // Slightly lighter background on focus
                border: 1px solid #7949ff;
              }
            }

            .flags-field {

              // background-color: #242526;
              .css-1v6rbs3-control:hover {
                border-color: $darkBorder !important;
              }

              .css-1g0hrsa-control {
                border: 1px solid #7949ff;

                &:focus {
                  // border-color: #007bff; // Border color on focus
                  background-color: #242526; // Slightly lighter background on focus
                  border: 1px solid #7949ff;
                }
              }

              .css-8lesw1-control {
                // background-color: #242526;
                border: 1px solid $darkBorder;
                color: $light_white_text;

                .css-1v6rbs3-control {
                  div {
                    &:hover {
                      border: 1px solid #7949ff;
                    }
                  }

                  &:hover,
                  &:focus {
                    border: 1px solid $darkBorder;
                  }
                }
              }

              input {
                // border: none !important;
                box-shadow: none !important;
                outline: none !important;
                padding: 0 !important;

                &:focus {
                  border: 1px solid $darkBorder;
                }

                &::placeholder {
                  color: #c3c3c3 !important;
                }
              }

              .css-mya99o-control {
                border: 1px solid $darkBorder;
              }

              .css-yt9ioa-option {
                background-color: transparent; // Remove default background
                color: $light_white_text; // Text color for options

                &:hover {
                  background-color: transparent !important; // Disable hover background
                  color: #e3e3e3 !important; // Optionally set hover text color
                }

                &.css-1n7v3ny-option {
                  background-color: #444 !important; // Highlight selected option background
                }
              }
            }

            &.job-title {
              color: #e3e3e3 !important;

              input {
                color: #e3e3e3 !important;

                &::placeholder {
                  color: #444746;
                }
              }

              &::after {
                display: none;
              }
            }

            &::after {
              background-image: url("../images/down-arrow-dark.svg");
            }
          }

          .form-input {
            .single-field {
              textarea {
                border-color: $darkBorder;
                color: #e3e3e3;
              }

              input {
                color: #e3e3e3;
                border-color: $darkBorder;

                &:focus {
                  border: 1px solid #7949ff; // Darken border color on focus for better visibility
                  box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
                }

                &::placeholder {
                  color: #444746;
                }
              }
            }
          }
        }

        .form-footer {
          .custom-checkbox {
            .checkmark {
              border: 2px solid white;
            }
          }
        }
      }
    }
  }

  .light-theme-img {
    display: none !important;
  }

  .dark-theme-img {
    display: block !important;
  }

  .listing-table {
    table {
      tr {

        th,
        td {
          background: $lightDarkBg !important;
        }
      }

      tbody {
        td {
          border-top: 1px solid $darkBorder !important;
          border-bottom: 1px solid $darkBorder !important;

          &:first-of-type {
            border-left: 1px solid $darkBorder !important;
          }

          &:last-of-type {
            border-right: 1px solid $darkBorder !important;
          }
        }

        .docs {

          img,
          svg {
            filter: $light_white_img_color;
          }
        }
      }
    }
  }

  .accordions-wrapper {
    .aboutus-accordion {
      .accordion-item {
        background: $lightDarkBg !important;
        border: none;

        .accordion-body {
          color: $light_white_text !important;
        }
      }
    }
  }

  .legal-topbar {
    border-bottom: 1px solid $darkBorder !important;

    .icon,
    .upload-icon {
      background-color: $darkBorder !important;

      img,
      svg {
        filter: $light_white_img_color;
      }
    }
  }

  .sidebar-title {
    border-right: 1px solid $darkBorder !important;
  }

  .legal-details-sidebar {
    border-right: 1px solid $darkBorder !important;

    ul {
      li {

        &.active,
        &:hover {
          a {
            background-color: $darkBorder;
          }
        }
      }
    }
  }

  .brand-page-wrapper {
    .brand-card-wrapper {
      margin: 15px 0 0;

      .brand-card {
        background: $lightDarkBg;
        border: 1px solid $darkBorder;
      }
    }
  }

  .contact-us-form-wrapper {
    .contact-us-form {
      label {
        color: $light_white_text !important;
      }
    }
  }

  .aboutNew {
    .Ai-image-container {
      .text-sec {
        .comp1 {
          button {
            background: $lightDarkBg;
            border-color: $darkBorder !important;
          }
        }
      }
    }

    .about-btn {
      .buttons {
        background: $lightDarkBg !important;
        border: 1px solid $darkBorder !important;
        color: $light_white_text !important;

        &.active {}
      }
    }
  }

  .cards-box {
    background: $lightDarkBg;
  }

  .build-content {
    background: $lightDarkBg;
    border-radius: 40px;

    .ilmiya-img {
      background: linear-gradient(45deg, $lightDarkBg, $lightDarkBg) padding-box, linear-gradient(to right, #7c4acf, #ffa96e, #ffa96e, #fe506f) border-box !important;
    }

    .build-btn {
      .build-btn2 {
        background: linear-gradient(45deg, $lightDarkBg, $lightDarkBg) padding-box, linear-gradient(to right, #7c4acf, #ffa96e, #ffa96e, #fe506f) border-box !important;
        color: $light_white_text;
      }
    }
  }

  .collab-modal {
    .modal-content {
      .modal-body {
        background: $lightDarkBg;
        padding-inline: 5px;

        .img-card {
          border: 1px solid $darkBorder !important;
        }
      }
    }
  }
}

.light-theme-img {
  display: block !important;
}

.dark-theme-img {
  display: none !important;
}

.header {
  position: sticky;
  top: 0;
  background: $white;
  z-index: 99;
  border-bottom: 1px solid var(--Primary-Light-50-Line, #dee1e6);

  // @media screen and (min-width: 1199px)  {
  //   .brand-dropdown-wrapper .brand-dropdown .custom-toggle .logo-second-img {
  //     display: block ;
  //   }
  // }
  .brand-dropdown-wrapper {
    .brand-dropdown {
      .custom-toggle {
        .logo-first-img {
          display: block;
        }

        .logo-second-img {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .brand-dropdown-wrapper {
      .brand-dropdown {
        .custom-toggle {
          .logo-first-img {
            display: none;
          }

          .logo-second-img {
            display: block;
          }
        }
      }
    }
  }

  .logo-wrapper {
    display: inline-block;

    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .brand-dropdown-wrapper {
      .brand-dropdown {
        >button {
          @media screen and (max-width: 767px) {
            padding: 0;
          }
        }

        img,
        svg {
          @media screen and (max-width: 767px) {
            width: 33px;
            height: 33px;
            object-fit: contain;
          }
        }

        .logo-second-img {
          @media screen and (max-width: 767px) {
            display: block;
          }
        }
      }
    }

    .logo {
      // width: 44px;
      // height: 44px;
      // display: inline-flex;
      // align-items: center;
      // justify-content: center;
      // background: $white;
      padding: 10px 20px !important;

      img,
      svg {
        width: 41px;
        height: 41px;
        border-radius: 4px;
      }
    }

    .hamburger {
      display: none;

      @media screen and (max-width: 991px) {
        display: none;
      }

      img,
      svg {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
  }

  .menu-wrapper {
    position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
    // padding: 10px 0;

    @media screen and (max-width: 991px) {

      // display: none;
      &.active {
        width: 100%;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #ffffff;
        z-index: 9;
        display: block;
        height: 100vh;
        overflow: auto;
      }
    }

    .menu-close-icon {
      display: none;
      width: 24px;
      height: 24px;
      color: $black;
      position: absolute;
      top: 20px;
      right: 20px;

      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    ul {
      margin-bottom: 0;
      padding: 0;
    }

    ul.menu {
      // text-align: end;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 8px;
      padding: 15px 0;

      @media screen and (max-width: 991px) {
        width: 100%;
        gap: 5px;
        padding: 9px 0;
      }

      li {
        display: inline-block;
        vertical-align: middle;

        &.bordered {
          border: 1px solid #dee1e6;
          padding: 5px;
          border-radius: 4px;
        }

        position: relative;
        font-size: 0;

        .profile-avatar {
          position: relative;
          display: inline-block;

          img {
            display: block;
            width: 100%; // Ensure the image scales properly
            height: auto;
            border-radius: 50%; // Make sure the avatar is circular
          }

          .online-dot {
            position: absolute;
            top: 0; // Adjust the positioning as needed
            right: 0; // Adjust the positioning as needed
            width: 10px; // Adjust the size of the online dot
            height: 10px; // Adjust the size of the online dot
            background-color: #4caf50; // Green color for the online indicator
            border-radius: 50%; // Make the dot circular
          }
        }

        img,
        svg {
          cursor: pointer;
        }

        p {
          margin: 0;
          color: var(--Primary-Main, #3b82f6);
          font-size: 14px;
          font-weight: 600;
        }

        .btn-style .text {
          margin: 0;
          color: var(--Primary-Main, #3b82f6) !important;
          font-size: 14px;
          font-weight: 600;
          background: transparent;
          border: none;
          padding: 0 !important;
        }

        svg img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          cursor: pointer;
        }

        a {
          color: var(--Primary-Main, #3b82f6);
          text-align: center;

          font-size: 14px;
          font-weight: 600;
        }

        @media screen and (max-width: 991px) {
          display: block;
          // padding: 0 !important;
          position: relative;
          font-size: 0;
        }

        &:not(&:last-of-type) {
          margin: 0 8px 0 0;

          @media screen and (max-width: 767px) {
            // margin: 0 0 10px;
            margin: 0 5px 0 0;
          }
        }

        &:hover {
          .navigation-menu {
            display: inline-block;
          }

          .header-modal {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition: 0.3s ease-in-out all;

            @media screen and (max-width: 576px) {
              transform: translate(64%, 0%) !important;
            }
          }
        }

        &.get-started-btn {
          display: none;

          @media screen and (max-width: 991px) {
            display: block;
          }
        }

        .dropdown-toggle,
        >a {
          color: $text-color !important;
          background-color: transparent;
          padding: 9px 15px 7px;
          border-radius: 6px;
          font-family: $poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          border: none;
          vertical-align: middle;

          &:hover,
          &.active {
            //   background: #f5f5f5;
          }

          @media screen and (max-width: 991px) {
            display: block;
            // padding: 5px 0 5px !important;
            position: relative;
            width: 100%;
          }
        }

        >a {
          padding: 11px 15px 10px;
          text-decoration: none;
          font-weight: 600;
          font-size: 12px;

          &.btn-style {
            //   background-color: $primary01;
            color: $primary01 !important;
            border-radius: 10px !important;
            padding: 8px 0 !important;
          }
        }

        .navigation-menu {
          position: absolute;
          background: $white;
          border-radius: 8px;
          z-index: 6;
          padding: 34px 40px 40px;
          left: auto;
          top: 81px;
          display: none;
          box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.1);

          @media screen and (max-width: 991px) {
            position: relative;
            top: 15px;
          }

          .inner-wrapper {
            strong.title {
              display: block;
              padding: 0 0 10px;
              border-bottom: 1px solid #e5e5e5;
              color: #404040;
              margin: 0 0 21px;
            }

            .main-wrapper {
              padding: 20px;
              height: 100%;
              display: grid;
              grid-template-columns: 25px auto;
              align-items: center;
              gap: 20px;
              transition: 0.3s ease-in-out all;
              cursor: pointer;

              &:hover,
              &.active {
                background: #dbeafe;
                transition: 0.3s ease-in-out all;
              }

              .icon-wrapper {

                img,
                svg {
                  width: 25px;
                  height: 25px;
                  object-fit: contain;
                }
              }

              .text-wrapper {
                text-align: left;

                strong,
                span {
                  display: block;
                  color: $black;
                }

                strong.small {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        // .header-modal {
        //   padding: 20px 10px;
        //   position: absolute;
        //   box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
        //   border-radius: var(--radius-md, 8px);
        //   background: $white;
        //   position: absolute;
        //   right: 0;
        //   transform:  translateY(-10px);
        //   opacity: 0;
        //   visibility: hidden;
        //   top: 55px;
        //   min-width: 360px;
        //   width: 100%;
        //   transition: 0.3s ease-in-out all;

        //   .modal-title {
        //     margin-bottom: 13px;
        //     border-bottom: 1px solid #dee1e6;
        //     margin-inline: 10px;
        //     h4 {
        //       font-size: 16px;
        //       font-weight: 600;
        //       line-height: 24px;
        //       color: #212121;
        //     }
        //   }
        //   ul {
        //     li {
        //       padding: 6px 10px;
        //       display: block;
        //       border-radius: 5px;
        //       &:hover {
        //         background-color: #edf4ff;
        //         a{
        //         color: #3b82f6 !important;
        //         }
        //         .icon-wrapper{
        //           img, svg{
        //             filter: brightness(0) saturate(100%) invert(45%) sepia(19%) saturate(3890%) hue-rotate(195deg) brightness(97%) contrast(98%) !important;
        //             &.no-filter{
        //               filter: none !important;
        //             }
        //           }
        //         }
        //       }

        //       &:not(&:last-of-type) {
        //         margin: 0 0 14px;
        //       }

        //       a {
        //         display: flex;
        //         gap: 12px;
        //         padding: 0 0 0;
        //         font-size: 14px;
        //         letter-spacing: -0.56px;
        //         line-height: normal;
        //         align-items: center;
        //         text-decoration: none;
        //         .icon-wrapper{
        //           background: #fff;
        //           width: 36px;
        //           height: 36px;
        //           box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        //           border-radius: 50%;
        //           display: flex;
        //           align-items: center;
        //           justify-content: center;
        //         }
        //         img {
        //           width: 20px;
        //           height: 20px;
        //           object-fit: contain;
        //           filter: brightness(0) saturate(100%) invert(16%) sepia(11%) saturate(246%) hue-rotate(169deg) brightness(95%) contrast(92%);
        //           &.no-filter{
        //             filter: none !important;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }

        .header-dropdown {
          .dropdown-menu {
            padding: 20px 10px;
            position: absolute;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
            border-radius: var(--radius-md, 8px);
            background: $white;
            min-width: 360px;
            width: 100%;

            @media screen and (max-width: 576px) {
              min-width: 230px !important;
            }
          }

          .brand-dropdown-wrapper {
            .brand-dropdown {
              .dropdown-toggle {
                padding: 0px;
                line-height: 0px !important;
              }

              .dropdown-menu {
                margin-top: 10px;
              }
            }
          }

          .icon {
            background: #fff;
            width: 36px;
            height: 36px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            border-radius: 50%;

            img {
              width: 20px;
              height: 20px;
              object-fit: contain;
              filter: brightness(0) saturate(100%) invert(16%) sepia(11%) saturate(246%) hue-rotate(169deg) brightness(95%) contrast(92%);

              &.no-filter {
                filter: none !important;
              }
            }
          }
        }
      }
    }
  }

  .btn-wrapper {
    text-align: right;
    text-decoration: none;
    background-color: $primary01;
    color: $white;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .mobile-view {
    .left-menu {
      width: 100%;
      display: block;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: $white;
      z-index: 9;

      ul.menu {
        width: 100%;
        padding: 15px 0 30px;

        li {
          display: block;
          padding: 0 0 0 !important;
          position: relative;

          a {
            display: block;
          }

          &:not(&:last-of-type) {
            padding: 15px 0;
            margin: 0 0 15px;

            &:after {
              content: "";
              border: solid black;
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 3px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              position: absolute;
              right: 10px;
              top: 18px;
            }
          }

          &:last-of-type {
            text-align: center;
          }

          .navigation-menu {
            position: relative;
            top: 10px;
            padding: 25px 20px 10px;
          }
        }
      }
    }
  }
}

//   .get-started-btn {
//     border: 1px solid #1e40af;
//     // background: $primary01;
//     color: $black !important;
//   }

.footer-bottom-bar {
  border-top: 1px solid $border;
  padding: 19.35px 40px 19.35px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: $white;

  p {
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

   .footer-logo {
    display: inline-flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .logo {
      max-width: 110px;
      width: 100%;
      height: auto;
    }
  }

  ul.bottom-menu {
    display: flex;
    margin-bottom: 0;
    justify-content: end;
    padding-left: 0;

    .second-sec{
      display: flex;
      gap: 10px;
      align-items: center;

      img{
        height: 14px;
        width: 14px;
        object-fit: contain;
      }
    }
    
    @media screen and (max-width: 767px) {
      justify-content: flex-end;
    }
    
    li {
      list-style: none;

      @media screen and (max-width: 991px) and (min-width: 768px) {
        margin: 0 30px 0 0;
      }

      &:not(&:last-of-type) {
        margin: 0 40px 0 0;
      }

      a {
        color: #404040 !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: none;
        transition: .3s ease-in-out all;

        &:hover {
          color: $primary01 !important;
          transition: .3s ease-in-out all;

          img,
          svg {
            filter: brightness(0) saturate(100%) invert(45%) sepia(50%) saturate(5043%) hue-rotate(206deg) brightness(105%) contrast(93%);
            transition: .3s ease-in-out all;
          }
        }

        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 125%;
        }

        img,
        svg {
          width: 20px;
          height: 20px;
          object-fit: contain;
          transition: .3s ease-in-out all;
        }
      }
    }
  }

  .social-links {
    text-align: right;

    @media screen and (max-width: 767px) {
      text-align: center;
      margin: 30px 0 0;
    }

    li {
      &:not(&:last-of-type) {
        margin: 0 40px 0 0;
      }

      a {

        img,
        svg {
          color: #525252;
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }
    }
  }
}

.request-demo-btn {
  a {
    text-decoration: none;
    color: $white;
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    background: $primary01;
    padding: 12px 20px;
    border-radius: 6px;

    @media screen and (max-width: 767px) {
      padding: 6px 10px;
      padding: 8px 10px;
    }
  }
}

.flags-field {

  .css-1okebmr-indicatorSeparator,
  // Targets the indicator separator
  .css-tlfecz-indicatorContainer {
    // Targets the dropdown arrow
    display: none;
  }

  .css-1v6rbs3-control {
    .css-hlgwow {
      .css-19bb58m {
        input {
          &:focus {
            border: 1px solid #7949ff; // Darken border color on focus for better visibility
            box-shadow: 0 0 5px rgba(158, 158, 158, 0.3);
          }
        }
      }
    }
  }
}

.contact-us-page-new {
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  height: calc(100vh - 120px);
  overflow-y: auto;
  padding: 56px 0;

  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }

  // min-height: 100vh;
  // height: 100%;
  width: 100%;

  // background-image: url('../images/contact-us-bg.png');
  .contact-page-content {
    padding-right: 50px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
    }

    .title {
      h1 {
        font-size: 33px;
        font-weight: 600;
        line-height: 40px;
        color: #2d2f31;
        margin-bottom: 0;

        @media screen and (max-width: 1199px) {
          font-size: 24px !important;
          line-height: 32px !important;
        }

        span {
          background: linear-gradient(90deg, #ffa96e, #fe506f);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;

          @media screen and (max-width: 1199px) {
            font-size: 24px !important;
            line-height: 32px !important;
          }
        }
      }
    }

    .content-section {
      margin-top: 26px;

      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }

      p {
        margin-bottom: 0;
        color: #737373;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .contactus-accordions-wrapper {
        margin: 25px 0 0;

        .contactus-accordion {
          .accordion-item {
            margin: 0 0 14px;
            box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
            border: 1px solid #dee1e6;
            border-radius: 10px;

            .accordion-header {
              button {
                background: transparent;
                font-weight: 600;

                .title {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  strong {
                    font-weight: 600;
                    color: #171717;
                    font-size: 19px;
                    line-height: 26px;

                    @media screen and (max-width: 767px) {
                      font-size: 14px;
                    }
                  }
                }
              }

              .accordion-button {
                background: transparent;

                &:focus {
                  box-shadow: none;
                }

                &.collapsed {
                  &::after {
                    content: "";
                    background-image: url("../images/icons/plus-icon.svg");
                    position: absolute;
                    right: 10px;
                    max-width: 18px;
                    width: 100%;
                    height: 18px;
                    background-size: contain;
                  }
                }

                &::after {
                  content: "";
                  background-image: url("../images/icons/plus-icon-blue.svg");
                  position: absolute;
                  right: 10px;
                  max-width: 18px;
                  width: 100%;
                  height: 18px;
                  background-size: contain;
                  transform: none !important;
                }

                &:not(.collapsed) {
                  background: transparent;
                  box-shadow: none;
                }
              }
            }

            .accordion-collapse {
              background: transparent;

              .accordion-body {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #737373;
                padding-top: 0;

                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .text-wrapper {
        &:not(&:last-of-type) {
          margin: 0 0 56px;

          @media screen and (max-width: 991px) {
            margin: 0 0 20px;
          }
        }

        strong {
          display: block;
          margin: 0 0 10px;
          font-weight: 600;

          @media screen and (max-width: 991px) {
            margin: 0 0 10px;
          }
        }

        p {
          margin: 0 0 0;
        }
      }
    }
  }

  .contact-us-form-wrapper {
    display: flex;
    max-width: 700px;
    margin-inline: auto;
    // height: 595px;
    justify-content: center;
    align-items: center;
    gap: 51.669px;
    margin: 0 auto;
    flex-shrink: 0;
    padding: 20px;
    border-radius: 16.147px;
    background: #fff;
    box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee1e6;

    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }

    .contact-us-form {

      // padding: 0 20px;
      .form-title {
        text-align: start;
        display: flex;
        flex-direction: column;
        background-color: transparent !important;
        margin-bottom: 30px;

        strong {
          color: black;
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
        }

        span {
          color: #737373;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .input-fields {
        // margin-top: 10px;
        display: flex;
        flex-direction: column;

        .single-field {
          display: flex;
          flex-direction: column;
          // gap: 8px;
          margin-bottom: 8px;

          .error-msg {
            color: red;
            font-size: 12px;
            width: 100%;
            margin-top: 6px;
            margin-bottom: 6px !important;
          }

          label {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #2d2f31;
            margin-bottom: 8px;
          }

          textarea {
            border-radius: 7px;
            border: 1px solid var(--line, #dee1e6);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            width: 100%;
            border-radius: 5px;
            font-size: 18px;
            outline: none;
            background-color: transparent; // Ensure input has a consistent background
            transition: border-color 0.3s, box-shadow 0.3s;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            padding: 17px 10px;

            &:focus {
              border: 1px solid #7949ff; // Darken border color on focus for better visibility
              box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
            }

            &::placeholder {
              color: #c3c3c3;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }

        .form-input {
          margin-bottom: 10px;

          .error-msg {
            color: red;
            font-size: 12px;
            width: 100%;
            margin-top: 6px;
            margin-bottom: 6px !important;
          }

          div {
            width: 100%;

            label {
              &.Mui-focused {
                color: #000;
              }
            }

            input,
            textarea {
              border-radius: 7px;
              border: 1px solid var(--line, #dee1e6);
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
              width: 100%;
              outline: none;
              background-color: transparent; // Ensure input has a consistent background
              transition: border-color 0.3s, box-shadow 0.3s;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              padding: 15px 10px;

              @media screen and (max-width: 991px) {
                padding: 10px 10px;
                font-size: 12px;
                line-height: 18px;
              }

              &::placeholder {
                color: #c3c3c3 !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;

                @media screen and (max-width: 991px) {
                  font-size: 12px;
                  line-height: 18px;
                }
              }

              &:focus {
                border: 1px solid #7949ff; // Darken border color on focus for better visibility
                box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
              }
            }
          }
        }

        .phone-input {
          position: relative;
          margin-bottom: 20px;

          label {
            position: absolute;
            top: -1%;
            left: 18px; // Adjusted to give space for the flag dropdown
            transform: translateY(-50%);
            background-color: #fff;
            padding: 0 5px;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            color: black;
            transition: all 0.3s ease-in-out;
            pointer-events: none;
          }

          .react-tel-input {
            .form-control {
              width: 100%;
              padding-left: 60px; // Adjust padding to account for the flag dropdown
              border-radius: 7px;
              border: 1px solid var(--line, #dee1e6);
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
              font-size: 14px;
              padding: 31px 0;
              background-color: #fff;
              transition: border-color 0.3s, box-shadow 0.3s;
              background: transparent;

              &:focus {
                border: 1px solid #7949ff; // Darken border color on focus for better visibility
                box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
              }
            }

            .flag-dropdown {
              border: 0;
              background: none;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              padding: 0 10px;
            }

            .special-label {
              display: none; // Hide the default label provided by react-phone-input-2
            }

            input[type="tel"] {
              padding-left: 60px; // Adjust input text padding to align with the label and flag
            }

            &:focus-within {
              .form-control {
                border-radius: 7px;
                border-color: #dee1e6;
                box-shadow: 0 0 5px rgba(158, 158, 158, 0.3);
              }

              label {
                top: 0;
                left: 60px; // Adjust label position to align with the input text
                font-size: 10px;
                color: #000;
              }
            }
          }

          input[type="tel"]:not(:placeholder-shown)+label {
            top: 0;
            left: 60px;
            font-size: 10px;
            color: #000;
          }
        }

        .input-wrapper {
          position: relative;

          // margin-bottom: 10px;
          .students-dropdown {
            .dropdown-toggle {
              background: transparent;
              border: 1px solid #dee1e6;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px 12px 12px 20px;
              max-width: 100%;
              width: 100%;
              color: #c3c3c3;
              font-size: 14px;
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

              @media screen and (max-width: 767px) {
                padding: 7px 12px 7px 12px;
                font-size: 12px;
                line-height: 18px;
              }

              &::after {
                display: none;
              }

              &.btn:active {
                background: transparent;
                color: #c3c3c3;
              }

              &.show {
                background: transparent;
                color: #c3c3c3;
              }

              &:hover {
                background: transparent;
                color: #c3c3c3;
              }
            }

            .dropdown-menu {
              max-width: 100%;
              width: 100%;

              .dropdown-item {
                @media screen and (max-width: 767px) {
                  font-size: 12px;
                  line-height: 18px;
                }

                &:hover {
                  background-color: #edf4ff;
                }
              }
            }
          }

          &.job-title {
            &::after {
              display: none;
            }
          }

          // &::after {
          //   content: "";
          //   background-image: url("../images/arrow-down-dropdown.svg");
          //   background-size: contain;
          //   background-repeat: no-repeat;
          //   width: 16px;
          //   height: 16px;
          //   position: absolute;
          //   right: 12px;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   pointer-events: none;
          //   transition: transform 0.3s; // Smooth rotation
          // }

          select {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 100%;
            padding: 14.5px 12px;
            border-radius: 4px;
            border: 1px solid #ccc;
            background-color: #fff;
            font-size: 14px;
            cursor: pointer;
            padding-right: 40px;

            &:focus {
              border: 1px solid #7949ff; // Darken border color on focus for better visibility
              box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
            }

            &::placeholder {
              font-size: 14px;
            }
          }

          .flags-field {
            .css-mya99o-control {
              border: 1px solid var(--line, #dee1e6);

              &:focus {
                outline: none;
              }
            }

            .css-1wy0on6 {
              display: none;
            }
          }

          label {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            background-color: #fff; // Set this to match the input background
            padding: 0 5px;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            transition: all 0.3s ease-in-out;
            pointer-events: none;
          }

          input,
          select,
          textarea {
            border-radius: 7px;
            border: 1px solid var(--line, #dee1e6);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            width: 100%;
            // padding: 10px;
            padding-left: 10px;
            border-radius: 5px;
            font-size: 14px;
            outline: none;
            background-color: transparent; // Ensure input has a consistent background
            transition: border-color 0.3s, box-shadow 0.3s;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            padding: 14.5px 10px;

            &:focus {
              border: 1px solid #7949ff; // Darken border color on focus for better visibility
              box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
            }

            &.educational-dropdown {
              border: 1px solid var(--line, #dee1e6);

              &:focus {
                border: 1px solid #7949ff; // Darken border color on focus for better visibility
                box-shadow: 0 0 5px rgba(158, 158, 158, 0.3); // Add subtle shadow on focus
              }
            }

            &:focus+label,
            &:not(:placeholder-shown)+label {
              top: 0;
              left: 18px;
              font-size: 12px;
              color: #000; // Change label color on focus or if input has a value
            }
          }

          textarea {
            height: auto;
            resize: none;
          }
        }
      }

      .form-footer {
        display: flex;
        align-items: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;

        span {
          line-height: 22.5px;
          font-size: 14px;
          font-weight: 400;
          color: #2d3748;

          @media screen and (max-width: 767px) {
            line-height: 20.5px;
            font-size: 12px;
          }

          a {
            color: #2181fa !important;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;

            @media screen and (max-width: 767px) {
              line-height: 20.5px;
              font-size: 12px;
            }
          }
        }

        .custom-checkbox {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 10px;

          input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            position: absolute;
            opacity: 0;
          }

          .checkmark {
            width: 15px;
            min-width: 15px;
            height: 15px;
            border: 2px solid #404040;
            border-radius: 4px;
            position: relative;
            display: inline-block;
            margin-right: 8px;
            transition: border-color 0.3s ease, background-color 0.3s ease;

            @media screen and (max-width: 767px) {
              width: 25px;
              height: 18px;
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
              width: 30px;
              height: 18px;
            }

            &::after {
              content: "";
              position: absolute;
              display: none;
              left: 3.5px;
              top: 1px;
              width: 4px;
              height: 7px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);

              @media screen and (min-width: 768px) and (max-width: 991px) {
                left: 4px;
                top: 1.5px;
                width: 4px;
                height: 7px;
              }

              @media screen and (max-width: 767px) {
                top: 2px;
              }
            }
          }

          input[type="checkbox"]:checked+.checkmark {
            border-color: #007bff; // Change border color when checked
            background-color: #007bff; // Optional: background color when checked

            &::after {
              display: block; // Show the tick mark when checked
            }
          }
        }
      }

      .get-started-btn {
        background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
        padding: 13px 20px;
        border-radius: 10px;
        max-width: 130px;
        width: 100%;
        border: none;
        color: #ffffff;
        font-weight: 600;

        @media screen and (max-width: 991px) {
          font-size: 12px;
          line-height: 18px;
          padding: 10px 8px;
        }

        a {
          text-decoration: none;
          color: white;
          font-weight: 500;
          white-space: nowrap;

          span {
            height: 44px;
          }
        }
      }
    }
  }

  .mobileview-accordions {
    @media screen and (min-width: 768px) {
      display: none;
    }

    .contactus-accordion {
      margin-top: 14px;

      .accordion-item {
        margin: 0 0 14px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid #dee1e6;
        border-radius: 10px;

        .accordion-header {
          button {
            background: transparent;
            font-weight: 600;

            .title {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              strong {
                font-weight: 600;
                color: #171717;
                font-size: 19px;
                line-height: 26px;

                @media screen and (max-width: 767px) {
                  font-size: 14px;
                }
              }
            }
          }

          .accordion-button {
            background: transparent;

            &:focus {
              box-shadow: none;
            }

            &.collapsed {
              &::after {
                content: "";
                background-image: url("../images/icons/plus-icon.svg");
                position: absolute;
                right: 10px;
                max-width: 18px;
                width: 100%;
                height: 18px;
                background-size: contain;
              }
            }

            &::after {
              content: "";
              background-image: url("../images/icons/plus-icon-blue.svg");
              position: absolute;
              right: 10px;
              max-width: 18px;
              width: 100%;
              height: 18px;
              background-size: contain;
              transform: none !important;
            }

            &:not(.collapsed) {
              background: transparent;
              box-shadow: none;
            }
          }
        }

        .accordion-collapse {
          background: transparent;

          .accordion-body {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #737373;

            @media screen and (max-width: 767px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.request-demo-btn {
  a {
    text-decoration: none;
    color: $white;
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    background: $primary01;
    border: 1px solid transparent;
    padding: 12px 20px;
    border-radius: 6px;
    transition: .3s ease-in-out all;

    &:hover {
      background: transparent;
      color: $primary01 !important;
      border: 1px solid $primary01;
      transition: .3s ease-in-out all;
    }

    @media screen and (max-width: 767px) {
      padding: 8px 10px;
    }
  }
}

.home-page {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  // background-image: url('../images/home-bg.png');
  .home-page-content {
    padding: 131px 0 0 0;
    position: relative;
    height: calc(100vh - 135px);
    overflow-y: auto;
    z-index: 10;

    @media screen and (max-width: 991px) {
      padding: 56px 0 0 0;
    }

    // @media screen and (max-height:950px){
    //   padding: 131px 0 0px 0;
    // }
    .page-banner {
      img {
        // position: absolute;
        bottom: 0;
        max-width: 715px;
        width: 100%;
        object-fit: contain;
        height: auto;

        @media screen and (max-height: 950px) {
          position: relative;
          // margin-top: -120px;
          margin-top: 50px;
        }

        // @media screen and (max-width:767px) {
        //   margin-top: -50px;
        // }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 767px) {
      padding: 56px 0 0 0;
      height: auto;
    }

    .page-header {
      display: block;
      overflow: hidden;

      h1 {
        font-size: 60px;
        line-height: 72px;
        font-weight: 600;
        color: #262626;
        margin-bottom: 0;

        @media screen and (max-width: 767px) {
          font-size: 39px;
          font-weight: 600;
          line-height: 50px;
          text-align: center;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
          font-size: 47px;
          font-weight: 600;
          line-height: 56px;
        }

        span {
          background: linear-gradient(90deg,
              #8a3dff,
              #6b65e0,
              #4098ff);
          /* Adjust colors to match your gradient */
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      @media screen and (max-width: 767px) {}

      .explore-btn {
        a {
          position: relative; // Required to position the pseudo-element correctly
          padding: 5px 10px;
          display: inline-flex;
          align-items: center;
          gap: 10px;
          color: $black;
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          white-space: nowrap;
          background-color: $white; // Ensuring the background color inside the border
          border-radius: 6px; // Apply border-radius (adjust if needed)
          overflow: hidden; // Ensures content respects the border-radius

          // Remove default border to make space for the pseudo-element
          border: none;

          // Pseudo-element for the gradient border
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: inherit; // Make the gradient border respect the parent’s border-radius
            padding: 1.5px; // Adjust the thickness of the gradient border (change if needed)
            background: linear-gradient(90deg,
                #7c4acf 0%,
                #69b7fa 38%,
                #ffa96e 76%,
                #fe506f 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }

          // Nested image styling
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .login-options {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: end;

        @media screen and (max-width: 767px) {
          display: none;
        }

        .single-option {
          a {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            font-size: 14px;
            color: #404040;
            font-weight: 400;
            line-height: 21px;
            gap: 13px;

            img {
              width: 8px;
              height: 14px;
            }
          }

          &.get-started {
            background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
            padding: 10px 20px;
            border-radius: 10px;
            max-width: 180px;
            width: 100%;
            text-align: center;

            a {
              color: $white;
              font-weight: 600;
            }
          }
        }
      }
    }

    .page-desciption {
      margin-top: 12px;

      @media screen and (max-width: 426px) {
        margin: 0;
      }

      .explore-btn {
        margin-bottom: 40px;
        display: none;
        justify-content: center;

        @media screen and (max-width: 767px) {
          display: flex;
        }

        a {
          position: relative; // Required to position the pseudo-element correctly
          padding: 5px 10px;
          display: inline-flex;
          align-items: center;
          gap: 10px;
          color: $black;
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          white-space: nowrap;
          background-color: $white; // Ensuring the background color inside the border
          border-radius: 6px; // Apply border-radius (adjust if needed)
          overflow: hidden; // Ensures content respects the border-radius

          // Remove default border to make space for the pseudo-element
          border: none;

          // Pseudo-element for the gradient border
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: inherit; // Make the gradient border respect the parent’s border-radius
            padding: 1.5px; // Adjust the thickness of the gradient border (change if needed)
            background: linear-gradient(90deg,
                #7c4acf 0%,
                #69b7fa 38%,
                #ffa96e 76%,
                #fe506f 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }

          // Nested image styling
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .page-title {
        display: flex;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: 767px) {
          justify-content: center;
        }

        .logo {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }
        }

        .title {
          h1 {
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 0;
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin: 0;

        @media screen and (max-width: 426px) {
          margin-top: 20px;
        }

        @media screen and (max-width: 767px) {
          text-align: center;
        }
      }

      .login-options {
        display: none;
        gap: 20px;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 767px) {
          display: flex;
          margin-top: 40px;
        }

        .single-option {
          a {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            font-size: 14px;
            color: #404040;
            font-weight: 400;
            line-height: 21px;
            gap: 13px;

            img {
              width: 8px;
              height: 14px;
            }
          }

          &.get-started {
            background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
            padding: 10px 20px;
            border-radius: 10px;

            max-width: 180px;
            width: 100%;
            text-align: center;

            a {
              color: $white;
              font-weight: 600;
            }
          }
        }

        .buttons-wrapper {
          margin-top: 40px;

          @media screen and (max-width: 426px) {
            margin-top: 20px;
          }

          .login-button {
            a {
              padding: 18.5px 20px;
              background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: white;
              text-decoration: none;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;

              img {
                width: 16px;
                height: 16px;
              }
            }
          }

          .login-options {
            margin-top: 16px;
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: space-between;

            .single-option {
              position: relative;

              border-radius: 10px;
              // padding: 18.5px 20px;
              max-width: 100%;
              width: 100%;
              overflow: hidden;
              border: none;

              &.homepage-new-button {
                box-shadow: 0px 6px 6.4px rgba(0, 0, 0, 0.1);
              }

              a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $black;
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                white-space: nowrap;
                padding: 18.5px 20px;
                background-color: $white; // Inner content background
                border-radius: inherit; // Ensure the a tag matches the parent radius

                img {
                  width: 16px;
                  height: 16px;
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  border-radius: inherit;
                  padding: 2px;
                  background: linear-gradient(90deg,
                      #7c4acf 0%,
                      #69b7fa 38%,
                      #ffa96e 76%,
                      #fe506f 100%);
                  -webkit-mask: linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                  mask: linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                  -webkit-mask-composite: destination-out;
                  mask-composite: exclude;
                }
              }
            }
          }
        }
      }
    }
  }
}

.signup-page-wrapper {
  // background-image: url('../images/contact-us-bg.png');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  height: calc(100vh - 127px);
  overflow-y: auto;
  padding: 120px 0;

  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 84px 0;
  }

  .page-content {
    .content-wrapper {
      .title {
        h1 {
          font-size: 60px;
          font-weight: 600;
          line-height: 72px;

          @media screen and (max-width: 767px) {
            font-size: 33px;
            line-height: 40px;
          }

          @media screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 31px;
            line-height: 37.7px;
          }

          span {
            background: linear-gradient(90deg, #ffa96e 0%, #fe506f 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }

      .description {
        color: #737373;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .buttons-wrapper {
      margin-top: 40px;

      @media screen and (max-width: 426px) {
        margin-top: 20px;
      }

      .login-button {
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: white;
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          padding: 18.5px 20px;
          background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
          border-radius: 10px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .login-options {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;

        .single-option {
          position: relative;

          border-radius: 10px;
          // padding: 18.5px 20px;
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          border: none;

          &.homepage-new-button {
            box-shadow: 0px 6px 6.4px rgba(0, 0, 0, 0.1);
          }

          a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $black;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            white-space: nowrap;
            padding: 18.5px 20px;
            background-color: $white; // Inner content background
            border-radius: inherit; // Ensure the a tag matches the parent radius

            img {
              width: 16px;
              height: 16px;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: inherit;
              padding: 2px;
              background: linear-gradient(90deg,
                  #7c4acf 0%,
                  #69b7fa 38%,
                  #ffa96e 76%,
                  #fe506f 100%);
              -webkit-mask: linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
              mask: linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
              -webkit-mask-composite: destination-out;
              mask-composite: exclude;
            }
          }
        }
      }
    }
  }

  .signup-banner {
    display: flex;
    justify-content: end;

    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 40px;
    }

    img {
      max-width: 441px;
      width: 100%;
      height: auto;
      text-align: right;

      @media screen and (max-width: 767px) {
        max-width: 325px;
        margin: 0 auto;
      }

      &.light-theme-img {
        display: block;
      }

      &.dark-theme-img {
        display: none;
      }

      @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 325px;
        text-align: right;
      }
    }
  }
}

.aboutus-page-content {
  color: $light_white_text !important;
  padding-bottom: 100px;

  .aboutus-hero-section {
    margin-top: 50px;
    // background-image: url('../images/about-us-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    padding: 137px 0 30px 0;

    @media screen and (max-width: 767px) {
      padding: 93px 0 0 0;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 104px 0 0 0;
    }

    .logo-wrapper {
      text-align: right;

      img {
        max-width: 224px;
        width: 100%;
        max-height: 224px;
        height: 100%;

        @media screen and (max-width: 767px) {
          max-width: 93px;
          max-height: 93px;
          width: 100%;
          height: 100%;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
          max-width: 117px;
          max-height: 117px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .aboutus-title {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 72px;
        font-weight: 600;
        color: #171717;
        line-height: 80px;

        @media screen and (max-width: 767px) {
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 0;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
          font-size: 37.55px;
          line-height: 41.7px;
        }
      }

      p {
        color: #737373;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        margin: 0 0 0;
      }
    }
  }

  .aboutus-content {
    margin-top: 80px;

    .accordions-wrapper {
      .aboutus-accordion {
        .accordion-item {
          margin: 0 0 28px;
          box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
          border: none;

          .accordion-header {
            button {
              background: transparent;
              font-weight: 600;

              .title {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                strong {
                  font-weight: 600;
                  color: #171717;
                  font-size: 19px;
                  line-height: 26px;

                  @media screen and (max-width: 767px) {
                    font-size: 14px;
                  }
                }
              }
            }

            .accordion-button {
              background: transparent;

              &:focus {
                box-shadow: none;
              }

              &.collapsed {
                &::after {
                  content: "";
                  background-image: url("../images/icons/arrow-down.svg");
                  position: absolute;
                  right: 10px;
                  max-width: 10px;
                  width: 100%;
                  height: 6px;
                  background-size: contain;
                }
              }

              &::after {
                content: "";
                background-image: url("../images/icons/arrow-up.svg");
                position: absolute;
                right: 10px;
                max-width: 10px;
                width: 100%;
                height: 6px;
                background-size: contain;
                transform: none !important;
              }

              &:not(.collapsed) {
                background: transparent;
                box-shadow: none;
              }
            }
          }

          .accordion-collapse {
            background: transparent;

            .accordion-body {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;

              @media screen and (max-width: 767px) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

.not-found-page-wrapper {
  .page-content-wrapper {
    padding: 134px 0;
    // background-image: url("../images/not-found-bg.svg");
    // background-position: center;
    background-repeat: no-repeat;


    @media (min-width: 992px) {
      .container{
          max-width: 888px;
      }
  }

    // background-size: cover;
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }

    .page-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 767px) {
        padding: 20px 0 10px 0;
        text-align: center;
        margin-bottom: 50px;
      }

      h1 {
        font-size: 120px;
        font-weight: 600;
        line-height: 130px;
        position: relative; // Required to position the pseudo-element correctly
        border-bottom: 4px solid transparent;
        background: linear-gradient(90deg, #7c4acf 100%, #69b7fa 38%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 12px;

        @media screen and (max-width: 991px) {
          font-size: 90px;
          line-height: 100px;
        }

        &::after {
          content: "";
          position: absolute;
          max-width: 260px;
          width: 100%;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px; // Set the thickness of the bottom border
          background: linear-gradient(90deg, #eb00ff 0%, #3b82f6 100%);

          @media screen and (max-width: 767px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .description {

        h2 {
          font-size: 32px;
          font-weight: 400;
          line-height: 44px;
          margin-bottom: 7px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 40px;
          text-wrap: nowrap;
        }

        ol,
        ul {
          li {
            p {
              margin: 2px 0 5px;
            }
          }
        }
      }

      .back-button {
        max-width: 363px;
        width: 100%;

        a {
          background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
          border-radius: 6px;
          padding: 18.5px 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: $white;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .page-banner {
      text-align: right;

      img {
    max-width: 389px;
        width: 100%;
        height: auto;
      }
    }
  }
}

.legal-page-wrapper {
  .legal-page-content-wrapper {
    padding: 86px 0;
    height: calc(100vh - 120px);
    overflow-y: auto;

    @media screen and (max-width:767px){
      padding: 30px 0 70px;
    }
    .title-wrapper{
    .page-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        display: flex;
        flex-direction: column;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;

          @media screen and (max-width:767px) {
            width: 24px;
          height: 24px;
          }
        }
        
      strong {
        font-size: 60px;
        font-weight: 600;
        line-height: 72px;
        color: #262626;

        @media screen and (max-width:767px) {
          font-size: 24px;
          line-height: 30px;
        }

        @media screen and (max-width:991px) and (min-width:768px) {
          font-size: 48px;
        }
      }
      span{
        margin-top: 46px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #737373;

        @media screen and (max-width:767px){
          font-size: 14px;
        font-weight: 400;
        line-height: 20px;
              }

        @media screen and (max-width:991px) and (min-width:768px) {
    margin-top: 20px;
        }
      }
      }
    }
    .main-img-wrapper{
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width:991px){
       text-align: center;
      }

    .ilmiya-legal-center-img{
      height: 218px;
      max-width: 251.11px;
      object-fit: contain;
      margin-right: -35px;

      // @media screen and (max-width:991px) and (max-width:991px) {
      //   display: none;
      // }

      @media screen and (max-width:767px){
        height: 96.76px;
        max-width: 84px;
      }

      @media screen and (max-width:991px) and (min-width:768px){
        height: 179.7px;
        max-width: 156px;
      }
    }
  }
  }

    .table-title {
      margin-top: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative; // Required to position the pseudo-element correctly
      border-bottom: 1px solid #DEE1E6;
      padding-bottom: 24px;

      &::after {
        // content: "";
        // position: absolute;
        // left: 0;
        // bottom: -15px;
        // width: 100%;
        // height: 2px; 
        // background: linear-gradient(90deg, #eb00ff 0%, #3b82f6 100%);
      }

      .icon {
        width: 24px;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      strong {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    
      }
    }

    .listing-table {
      margin-top: 20px;
      overflow-x: auto;

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 10px;

        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 500;
              color: #000;
              line-height: 24px;
              padding: 8px 20px;

              &.docs {
                text-align: end;
              }
            }
          }
        }

        tbody {
          tr {
            cursor: pointer;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

            td {
              border: 1px solid #dee1e6;
              padding: 14px;

              img {
                width: 24px;
                height: 24px;
              }

              &:first-of-type {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-right: none;
              }

              &:last-of-type {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-left: none;
              }

              &.docs {
                display: flex;
                justify-content: end;
                padding: 21px;
              }

              .item-name {
                display: flex;
                align-items: center;
                gap: 10px;

                a {
                  padding-left: 10px;
                  text-decoration: none;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 24px;
                  color: #202223;

                  @media screen and (max-width: 767px) {
                    font-size: 14px;
                    white-space: nowrap;
                  }
                }

                .icon {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  padding: 6px;
                  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                    width: 16px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.legal-details-page-wrappper {
  max-width: 100%;
  width: 100%;

  .page-breadcrumb {
    border-bottom: 1px solid #dee1e6;    
    padding: 19.5px 20px; 

    strong {
      display: flex;
      color: #2d2f31;
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;

      @media screen and (max-width:767px){
        font-size: 13px;
      }

      .breadcrumb-icon-text{
        display: flex;
        align-items: center;
        gap: 4px;

        img{
          height: 22px;
          width: 22px;
          object-fit: contain;
        }
      }

      a {
        color: #2d2f31;
        text-decoration: none;
        margin-right: 5px;
      }
    }
  }


  .page-content-wrapper {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 188px);
    overflow-y: auto;
    display: flex;
    background-color: #fdfdfd;


    .page-content {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .top-image-wrapper {
        background: white;
        border-radius: 16px;
        margin-top: 42px;

        .ilmiya-legal-center-img{
          height: 218px;
          width: 251.11px;
          object-fit: contain;
    
          @media screen and (max-width:767px){
            height: 112px;
            width: 129.01px;
          }
    
          @media screen and (max-width:991px) and (min-width:768px){
            height: 207.34px;
            width: 180px;
          }
        }

        .ilmiya-subprocessor-page-image {
          display: flex;
          width: 100%;
          margin: 0px auto;
          height: auto;
          object-fit: contain;
          border-radius: 16px;
        }
      }

      .page-content-section {
        padding: 0 0 100px;
        max-width: 1100px;
        margin: auto;
        width: 100%;

        .page-bg-image {
          background-image: url('../images/privacyimage.png');
          background-color: rgb(249, 239, 228);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          width: 100%;
          margin: 0px auto;
          height: 320px;
          max-width: 1056px;
        }

        .page-content-container {
          max-width: 700px;
          margin: 24px auto 0;
          width: 100%;

          @media screen and (max-width:991px) and (min-width:768px){
            margin: 20px auto 0;
          }
          .privacy-content-section {

            .privacy-content-accordion {
              margin-top: 12px;
              color: #6a707c;

              .accordion-item {
                background-color: transparent !important;
                border: none !important;

                .accordion-button {
                  background-color: transparent !important;
                  color: #6a707c !important;
                  box-shadow: none !important;
                  border: none !important;
                  padding: 16px 0 !important;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  color: #6a707c !important;

                  &::after {
                    color: #6a707c !important;
                  }

                  &:not(.collapsed) {
                    background-color: transparent !important;
                    box-shadow: none !important;
                    padding: 16px 0 !important;
                  }
                }



                .accordion-body {
                  padding: 0;
                  border-bottom: 1px solid #ecedef;

                  // padding: 0px !important;
                  // margin: 0px !important;
                  .accordion-item {
                    // padding: 0px !important;
                    // margin: 0px !important;
                  }

                  a {
                    text-decoration: none;

                    .small {
                      padding: 6px 10px;
                    }
                  }

                  .small {
                    font-size: 16px;
                    line-height: 22px;
                    color: #5e646e;
                    padding: 9px 8px;
                    font-weight: 400;

                    &:hover {
                      background-color: #ecedef;
                      border-radius: 4px;
                      color: #3F434A;
                    }
                  }


                  .disc-style-ul {
                    list-style: none;
                    padding: 0;

                    li {
                      padding: 6px;
                      font-size: 15px;
                      line-height: 22px;
                      font-weight: 400;
                      position: relative;
                      list-style: none;
                      color: #5e646e;

                      a {
                        padding-left: 28px;
                        display: inline-block;

                      }

                      &:before {
                        content: "●";
                        color: #5e646e;
                        font-size: 9px;
                        position: absolute;
                        left: 16px;
                        top: 50%;
                        transform: translateY(-50%);
                      }

                      &:hover {
                        background-color: #ecedef;
                        border-radius: 4px;
                        color: #3F434A;
                      }
                    }
                  }

                  .circle-style-ul {
                    list-style: none;
                    padding: 0;

                    li {
                      padding: 6px;
                      font-size: 15px;
                      line-height: 22px;
                      font-weight: 400;
                      position: relative;
                      list-style: none;
                      color: #5e646e;

                      a {
                        padding-left: 45px;
                      }

                      &:before {
                        content: "";
                        width: 5px;
                        height: 5px;
                        border: 1px solid #5e646e;
                        border-radius: 50%;
                        position: absolute;
                        left: 32px;
                        top: 50%;
                        transform: translateY(-50%);

                      }

                      &:hover {
                        background-color: #ecedef;
                        border-radius: 4px;
                      }
                    }
                  }


                  ul {
                    list-style-type: disc;
                    list-style-position: inside;
                    background-color: transparent;
                    transition: background-color 0.3s ease-in-out;
                    padding-left: 18px;


                    a {
                      text-decoration: none;
                      color: inherit;


                      &:hover {
                        background-color: #ecedef;
                        border-radius: 4px;
                      }
                    }



                    li {
                      padding: 6px;
                      font-size: 15px;
                      line-height: 22px;
                      font-weight: 400;
                      list-style: circle;
                      color: #5e646e;

                      &:hover {
                        background-color: #ecedef;
                        border-radius: 4px;

                      }
                    }
                  }
                }
              }
            }
          }

        }

        .content-title {
          display: flex;
          justify-content: center;
          gap: 11px;

          .icon {
            width: 42px;
            height: 42px;

            img {
              width: 42px;
              height: 42px;
            }
          }

          h1 {
            font-weight: 700;
            font-size: 40px;
            line-height: 56px;
            color: #000000;
            margin: 0;

            @media screen and (max-width:767px) {
              font-size: 32px;
            }

            @media screen and (max-width:991px) and (min-width:768px) {
              font-size: 40px;
            }
          }
        }

        .content-wrapper {
          padding: 12px 0 0;

          .subprocessor-table-wrapper {
            overflow-x: auto;
            width: 100%;

            &::-webkit-scrollbar {
              height: 10px;
              // width: 8px;
            }

            &::-webkit-scrollbar-thumb {
              background: #eeeeee;
              border-radius: 24px;
            }

            table {
              background-color: #fdfdfd;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0;
              border-radius: 10px;
              overflow: hidden;
              margin-bottom: 3px;

              thead {
                tr {
                  th {
                    min-width: 191px;
                    padding: 8px 12px;
                    border-top: 1px solid #efefef;
                    text-align: start;
                    vertical-align: top;
                    border-left: 1px solid #efefef;
                    background-color: transparent;

                    &:last-child {
                      border-right: 1px solid #efefef;
                    }

                    strong {
                      line-height: 25px;
                      color: #3f434a;
                      font-size: 14px;
                      font-weight: 600;
                    }

                    &:first-child {
                      border-top-left-radius: 10px;
                    }

                    &:last-child {
                      border-top-right-radius: 10px;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    padding: 8px 12px;
                    border-left: 1px solid #efefef;
                    border-top: 1px solid #efefef;
                    text-align: start;
                    vertical-align: top;

                    &:first-child {
                      border-left: 1px solid #efefef;
                    }

                    &:last-child {
                      border-right: 1px solid #efefef;
                    }

                    a {
                      line-height: 25px;
                      color: #0e43a4;
                      font-size: 14px;
                      font-weight: 500;
                      text-decoration: none;
                    }

                    span {
                      line-height: 25px;
                      color: #3f434a;
                      font-size: 14px;
                    }
                  }

                  &:last-child {
                    td {
                      border-bottom: 1px solid #efefef;
                    }
                  }

                  &:last-child {
                    td {
                      &:first-child {
                        border-bottom-left-radius: 10px;
                      }

                      &:last-child {
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }
                }
              }
            }
          }

          .spacing-div {
            padding: 25px 0;
          }

          .description {
            margin: 24px 0 6px;

            &.high-space {
              margin: 60px 0px 6px;
            }

            ul {
              margin: 0;

              .li-inner-span-text{
                font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              color: #3f434a;
              margin-bottom: 0;

              @media screen and (max-width:767px) {
                font-size: 13px;
                line-height: 19px;
              }
              }
            }

            .subtitle-heading {
              font-size: 16px;
              line-height: 29px;
              color: #3f434a;
              font-weight: 600;
              margin-bottom: 2px;

              @media screen and (max-width:767px) {
                font-size: 14px;
              }
            }

            .parent-notification {
              border-top: 1px solid #ecedef;
              border-bottom: 1px solid #ecedef;   
               margin: 12px 0;
              padding: 12px 0px;

              strong {
                margin-left: 28px;
              }

              p {
                margin-top: 10px;
                margin-left: 28px;
              }

            }

            h2 {
              font-size: 21px;
              line-height: 29px;
              font-weight: 600;
              color: #3f434a;
              margin-bottom: 6px;

              @media screen and (max-width:767px) {
                font-size: 18px;
                line-height: 24px;
              }
            }

            p {
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              color: #3f434a;
              margin-bottom: 0;

              @media screen and (max-width:767px) {
                font-size: 13px;
                line-height: 19px;
              }

              .mail-text {
                color: #0e43a4;
                cursor: pointer;
              }

              .link-text {
                font-weight: 500;
                color: #0e43a4;
              }

              .terms-link-text {
                border-radius: 4px;
                padding: 2px 3px;
                background: rgba(22, 104, 227, .08);
                color: #0d3c82;
              }

              strong {
                font-weight: 600;
              }

              a {
                text-decoration: none;

                span {
                  // display: flex;
                  gap: 5px;
                  align-items: center;
                  color: #0e43a4;
                  border-radius: 4px;
                  display: inline-block;

                  &.bg-blue {
                    // background: rgb(87, 142, 224);
                    background-color: rgba(22, 104, 227, 0.08);
                    padding: 2px 4px;

                  }

                  svg {
                    color: rgb(87, 142, 224);
                    margin-right: 3px;
                  }
                }
              }

              span {
                font-weight: 500;
                // color: #2d2f31;
                color: #5e646e;
              }
            }

            ol {
              margin-bottom: 5px;
            }

            ol,
            ul {
              li {

                p,
                span {
                  margin: 2px 0 5px;
                }
              }
            }
          }

          .trial-account {
            margin-top: 26px;

            h4 {
              font-size: 24px;
              font-weight: 600;
              line-height: 32px;
              color: #3f434a;
            }

            ul {
              margin-top: 16px;

              li {
                // margin-bottom: 20px;

                span {
                  // font-size: 14px;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 26px;
                  // color: #737373;
                  color: #3f434a;

                  a {
                    text-decoration: none;
                    color: #737373;
                  }
                }
              }
            }
          }

          .suspend-section {
            margin-top: 20px;

            &.suspend-header {
              h4 {
                font-size: 21px;
                line-height: 29px;
                font-weight: 600;
              }
            }

            h4 {
              font-size: 21px;
              font-weight: 600;
              line-height: 30px;
              color: #2d2f31;
              // margin-bottom: 16px;
            }

            ol {
              li {
                margin-bottom: 20px;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 22px;
                  color: #737373;

                  a {
                    color: #737373;
                    text-decoration: none;
                  }
                }
              }
            }
          }

          .page-banner {
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.legal-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  border-bottom: 1px solid #dee1e6;

  .sidebar-title {
    padding: 10px 0;
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 208px;
    width: 100%;
    border-right: 1px solid #dee1e6;

    .icon {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      background-color: white;
      padding: 5px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
        height: 20px;
      }
    }

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .title {
    h2 {
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      text-align: end;
      margin-bottom: 0;
    }
  }

  .upload-icon {
    width: 40px;
    height: 40px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background-color: white;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;

    img {
      width: 20px;
      height: 18px;
    }
  }
}

.legal-details-sidebar {
  max-width: 228px;
  height: 100%;
  overflow-y: auto;
  padding: 10px 20px;
  border-right: 1px solid #dee1e6;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: block;

      &:not(&:last-of-type) {
        margin: 0 0 5px;
      }

      a {
        font-size: 10px;
        border-radius: 4px;
        padding: 10px;
        display: block;
        line-height: normal;
        text-decoration: none;
        color: #2d2f31;
        font-weight: 400;
      }

      &.active {
        font-size: 10px;
        border-radius: 5px;

        a {
          color: #3b82f6 !important;
          font-size: 10px;
          font-weight: 500;
          background-color: #edf4ff;
        }
      }

      &:hover {
        a {
          color: #3b82f6 !important;
          background-color: #edf4ff;
        }
      }
    }
  }

  .expand-icon {
    display: flex;
    justify-content: end;
    align-items: end;

    button {
      border: none;
    }
  }
}

.legal-details-sidebar {
  width: 300px;
  transition: transform 0.3s ease;
  transform: translateX(0);

  &.closed {
    transform: translateX(-100%);
  }

  .expand-icon {
    position: absolute;
    bottom: 10px;
    right: 40px; // Adjust this based on your design

    button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

.thank-you-page-wrapper {
  .page-content-wrapper {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 27px 0 90px 0;
    width: 100%;

    // background-image: url('../images/contact-us-bg.png');
    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 56px 0 27px 0;
    }

    @media screen and (max-width: 767px) {
      padding-top: 60px;
    }

    .page-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .page-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 20px 45px rgba(0, 0, 0, 0.1);
        padding: 115px 61px;
        border-radius: 16.16px;
        background-color: white;

        @media screen and (max-width: 767px) {
          // box-shadow: none;
          padding: 139px 12.5px;
          margin: 20px 0 0 0;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
          padding: 139px 49px;
        }

        .check-icon {
          padding: 18px;
          position: relative;
          border-radius: 15px;
          /* Adjust the radius */
          background: white;
          box-shadow: 0px 4px 30px rgba(195, 147, 255, 0.6);
          // box-shadow: inset 0px 1px 3px #3b41497D;
          border-radius: 32px;

          img {
            width: 84px;
            height: 84px;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 32px;
            /* Same radius as your element */
            padding: 1.5px;
            /* Same as the border width */
            background: linear-gradient(270deg,
                #7c4acf 0%,
                #69b7fa 38%,
                #ffa96e 76%,
                #fe506f 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: subtract;
            pointer-events: none;
          }
        }

        .content-section {
          margin-top: 64px;
          text-align: center;

          .title {
            h1 {
              span {
                background: linear-gradient(90deg,
                    #7c4acf 0%,
                    #69b7fa 38%,
                    #ffa96e 76%,
                    #fe506f 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 48px;
                /* Adjust as needed */
                font-weight: 600;
                line-height: 56px;

                @media screen and (max-width: 767px) {
                  font-size: 28px;
                  line-height: 34px;
                }
              }
            }
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #737373;

            @media screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

.brand-dropdown-wrapper {
  .brand-dropdown {
    .dropdown-toggle {
      background: transparent;
      border: none;

      &:hover {
        background: transparent;
      }

      &.btn:active {
        background: transparent;
      }

      &.show {
        background: transparent;
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      max-width: 143px;
      width: 100%;
      padding: 14px 10px;
      border: 1px solid #dee1e6;
      border-radius: 8px;    
      inset: 22px auto auto 0px !important;

      .dropdown-item {
        padding: 6px 10px;
        border-radius: 5px;
        
        &:hover {
          background-color: #edf4ff;
          .single-item{
            a{
              color: #2181fa;
            }
          }

          img,
          svg {
            filter: brightness(0) saturate(100%) invert(42%) sepia(80%) saturate(2379%) hue-rotate(202deg) brightness(98%) contrast(96%) !important;
          }

          span {
            color: #2181fa;
          }
        }

        .single-item {
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 5px;

          .icon {
            background-color: white;
            border-radius: 50%;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
            padding: 8px 10px 8px 9px;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              filter: brightness(0) saturate(100%) invert(16%) sepia(11%) saturate(246%) hue-rotate(169deg) brightness(95%) contrast(92%);

              &.no-filter {
                filter: none !important;
              }
            }
          }

          a {
            text-decoration: none;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: black;
          }
        }
      }
    }
    .apps-dropdown{
      @media screen and (min-width:576px) {
      transform: translate3d(-50%, 26px, 0) !important;
    }
  }
  }
}

.router-page-wrapper {
  padding: 137px 0 205px 0;

  // height: calc(100vh - 64px);
  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 24px 0 120px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 77px 0 273px 0;
  }

  .router-page-form {
    max-width: 550px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .form-wrapper {
      border-radius: 10px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      padding: 60px 65px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-logo {
        width: 62px;
        height: 62px;

        img {
          width: 62px;
          height: 62px;
        }
      }

      .form-title {
        margin-top: 40px;
        text-align: center;

        h1 {
          font-size: 32px;
          line-height: 48px;
          font-weight: 400;
          margin-bottom: 0;
          color: #202223;
        }
      }

      .form-input-section {
        margin-top: 50px;

        .input-section {
          display: flex;
          align-items: center;
          border: 1px solid #dee1e6;
          border-radius: 7px;

          &.error {
            border: 1px solid #ef4444;

            input {
              color: #ef4444;
            }
          }

          input {
            padding: 16px 20px;
            border: none;
            background: transparent;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #2d2f31;

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: #d4d4d4;
            }
          }

          .domain {
            padding: 16px 20px;
            background-color: #f5f5f5;
            border-radius: 7px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            span {
              color: #a29e9e;
              font-size: 12px;
              line-height: 14px;
              font-weight: 600;
            }
          }
        }

        .submit-btn {
          margin-top: 30px;

          button {
            background-color: #2181fa;
            color: white;
            border: none;
            border-radius: 7px;
            max-width: 100%;
            width: 100%;

            padding: 13px 0;
          }
        }
      }
    }

    .error-section {
      padding: 26px 50px;
      display: flex;
      align-items: center;
      justify-content: left;
      background-color: #fae0e0;
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
      gap: 20px;
      margin: 0 auto;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #2d2f31;
        margin-bottom: 0;
      }
    }
  }
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

//------------------- brand page -------------------------

.brand-page-wrapper {
  height: calc(100vh - 135px);
  overflow-y: auto;
  display: flex;
  align-items: center;
  margin-top: 120px;

  @media screen and (max-width:767px) {
    align-items: flex-start;
    margin-top: 35px;
    padding: 0 0 40px;
  }
  @media screen and (max-width:991px) and (min-width:768px) {
    margin-top: 76px;
  }

  .brand-heading {
    margin-bottom: 1px;

    h1 {
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      color: #262626;

      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
      }

      @media screen and (max-width: 991px) and (min-width: 768px) {
        font-size: 56px;
      }

      span {
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
        background: linear-gradient(90deg, #FFA96E 0%, #FE506F 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 767px) {
          font-size: 28px;
          line-height: 38px;
        }
      }
    }
  }

  .brand-content {
    // margin: 0 0 15px;

    p {
      color: #737373;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      margin: 0;
    }
  }

  .brand-card-wrapper {
    margin-top: 34px;
    .brand-card {
      width: 100%;
      border: 1px solid #dee1e6;
      box-shadow: 0px 1px 3px 0px #0000001A;
      border-radius: 14px;
      padding: 61px 20px;
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      height: 100%;

      @media screen and (max-width: 767px) {
        padding: 30px 10px;
      }

      img {
        max-width: 269px;
        height: 70px;
        width: 100%;
        object-fit: contain;

        @media screen and (max-width: 767px) {
          max-width: 140px;
          object-fit: contain;
          height: 30px;
        }
      }

      .download-btn {
        position: absolute;
        top: 20px;
        right: 20px;

        @media screen and (max-width:767px) {
          top: 5px;
    right: 5px;
        }

        a {

          img,
          svg {
            width: 24px;
            height: 24px;
            object-fit: contain;

            @media screen and (max-width:767px) {
              width: 20px;
              height: 20px;
            }

          }
        }
      }
    }
  }
}

// -------About New page--------

.aboutNew {
  .learning-reimagined {
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 39px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.02em;
      width: 100%;
      margin-top: 139px;
    }

    img {
      margin-top: -100px;
      width: 100%;
    }
  }

  .Ai-image-container {
    margin: 154px 0px;
    text-align: center;

    .main-img-wrapper {
      display: inline-block;
      position: relative;

      .text-sec {
        max-width: 300px;
        text-align: left;
        width: 100%;

        .comp1 {
          display: flex;
          gap: 12px;
          align-items: center;
          white-space: nowrap;
          margin: 0px 0 11px;

          strong {
            font-size: 28px;
            font-weight: 600;
            line-height: 28px;
            color: #262626;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #dee1e6;
            border-radius: 4px;
            background: transparent;
            width: 36px;
            height: 36px;

            img {
              height: 24px;
              width: 24px;
            }
          }
        }

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 12.6px;
          color: #737373;
        }
      }

      .embeded-ai {
        position: absolute;
        top: -56px;
        right: -237px;
      }

      .custom-built {
        position: absolute;
        top: 50%;
        left: -300px;
        transform: translateY(-50%);
      }

      .zero-hassle {
        right: -240px;
        position: absolute;
        bottom: -41px;
      }
    }
  }

  .management {
    display: block;
    text-align: center;
    margin-top: 71px;

    .about-text {
      margin-bottom: 80px;

      h3 {
        font-weight: 600;
        font-size: 39px;
        line-height: 110%;
        letter-spacing: -0.02em;
        background: linear-gradient(90deg, #7c4acf 1.44%, #69b7fa 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 22px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 135%;
        text-align: center;
        color: rgba(115, 115, 115, 1);
      }
    }

    .about-btn {
      display: flex;
      gap: 18px;
      padding: 13px;
      margin-bottom: 56px;
      width: 100%;

      .buttons {
        width: 100%;
        padding: 30px;
        gap: 10px;
        border-radius: 13px;
        background-color: white;
        border: 1px solid rgba(222, 225, 230, 1);
        border-radius: 13px;
        transition: .3s ease-in-out all;

        &.active {
          color: #2D2F31;
          font-size: 16px;
          font-weight: 500;
          line-height: 23.2px;
          border-radius: 10px !important;
          background: 0 0;
          background:
            linear-gradient(45deg, #EEFDF6E5, #EEFDF6E5) padding-box,
            linear-gradient(to right, #fe506f, #ffa96e, #69b7fa, #7c4acf) border-box;
          border: 1px solid transparent;
          transition: .3s ease-in-out all;
        }
      }
    }

    .active-tabs-section {
      display: flex;
      justify-content: center;

      .gradient-border-bg {
        background: linear-gradient(45deg, #BEA1FF4D, #9DE5FF4D);
        border-radius: 16px;
        padding: 22.06px 20.65px 21.94px 21.35px;

        .about-page-people-tabs-ui {
          padding: 59.94px 0px 109px 0px;
          max-width: 908px;
          width: 100%;
          border: 1px solid #CFCFCF;
          border-radius: 16px;
          box-shadow: 0px 1.89px 3.78px 0px #120F281F;
          background-color: #ffffff;

          .sec1 {
            .top-section {
              padding: 0px 65px;

              .request-demo-btn {
                a {
                  text-decoration: none;
                  color: $white;
                  font-weight: 600;
                  white-space: nowrap;
                  font-size: 12px;
                  background: $primary01;
                  padding: 12px 20px;
                  border-radius: 6px;

                  @media screen and (max-width: 767px) {
                    padding: 6px 10px;
                    padding: 8px 10px;
                  }
                }
              }

              h1 {
                font-size: 39px;
                font-weight: 600;
                line-height: 48px;
                margin-bottom: 20px;

                .title-text1 {
                  color: #262626;
                }

                .title-text2 {
                  // background: linear-gradient(#FFA96E, #FE506F);
                  background: linear-gradient(to right, #FFA96E, #FE506F);
                  background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }

              p {
                font-size: 14px;
                font-weight: 400;
                line-height: 135%;
                color: #737373;
                margin-bottom: 30px;
              }
            }
          }

          .sec2 {
            margin-top: 84px;
          }


        }

        .about-page-engage-tabs-ui {
          padding: 52px 44px 64px 44px;
          max-width: 908px;
          width: 100%;
          border: 1px solid #CFCFCF;
          border-radius: 16px;
          box-shadow: 0px 1.89px 3.78px 0px #120F281F;
          background-color: #ffffff;

          .text-card {
            text-align: left;

            .text-comp1 {
              display: flex;
              align-items: center;
              gap: 8px;
              margin-bottom: 8.5px;

              img {
                height: 24px;
                width: 24px;
              }

              strong {
                font-size: 16px;
                font-weight: 500;
                line-height: 23.2px;
                color: #2D2F31;
              }
            }

            p {
              strong {
                font-size: 14px;
                font-weight: 500;
                line-height: 20.3px;
                color: #2D2F31;
              }

              span {
                font-size: 14px;
                font-weight: 400;
                line-height: 12.6px;
                color: #737373;
              }
            }
          }

          .img-wrapper {

            img,
            svg {
              width: 100%;
            }
          }
        }

        .about-page-adminis-tabs-ui {
          padding: 0px 0px 109px 0px;
          max-width: 908px;
          width: 100%;
          border: 1px solid #CFCFCF;
          border-radius: 16px;
          box-shadow: 0px 1.89px 3.78px 0px #120F281F;
          background-color: #ffffff;
          overflow: hidden;

          .top-img-container {
            position: relative;

            .administrationimg1 {
              position: absolute;
              top: -3px;
              left: -12px;
            }

            .administrationimg2 {
              position: absolute;
              top: 10px;
              right: -12px;
            }
          }

          .inner-content-administration-tab {
            padding: 119.94px 116.35px;

            img {
              width: 184.48px;
              height: 159.23px;
              object-fit: contain;
            }

            h1 {
              font-size: 39px;
              font-weight: 600;
              line-height: 48px;
              margin-bottom: 5px;

              .title-text1 {
                color: #262626;
              }

              .title-text2 {
                background: linear-gradient(to right, #FFA96E, #FE506F);
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 145%;
              color: #737373;
              margin-bottom: 35px;
              padding: 0px 71.5px;
            }
          }

          .bottom-img-container {
            position: relative;

            .administrationimg3 {
              position: absolute;
              top: -87px;
              left: -10px;
            }

            .administrationimg4 {
              position: absolute;
              top: -84px;
              right: -12px;
            }
          }
        }

        .about-page-digital-tabs-ui {
          padding: 19px 19px 0px 19px;
          max-width: 908px;
          width: 100%;
          border: 1px solid #CFCFCF;
          border-radius: 16px;
          box-shadow: 0px 1.89px 3.78px 0px #120F281F;
          background-color: #ffffff;

          .background-boxes-image {
            background-image: url("../images/boxesbackgroundimage.png");
            width: 871px;

            .digital-tab-relative-imgs {
              position: relative;
              text-align: center;
              padding: 79px 171px 46px 191px;

              .comment-img {
                position: relative;
                left: -72px;
                top: 2px;
              }

              .cmsdraft-img {
                position: relative;
                top: -8px;
                left: -20px;
              }

              .accessibility-img {
                position: relative;
                top: 0;
                left: 26px;
              }

              .share-img {
                position: relative;
                top: 20px;
                left: 80px;
              }

              .digital-img {
                position: relative;
                top: 38px;
                left: 0;
              }

              .future-img {
                position: relative;
                bottom: -64px;
                left: -100px;
              }

              .layout-img1 {
                position: relative;
                top: 75px;
                left: -22px;
              }

              .layout-img2 {
                position: relative;
                right: -61px;
                top: 66px;
              }
            }
          }

          .sec1 {
            .top-section {
              padding: 0px 117px 0px 117px;
              margin: 130px 0px 95px 0px;

              .request-demo-btn {
                a {
                  text-decoration: none;
                  color: $white;
                  font-weight: 600;
                  white-space: nowrap;
                  font-size: 12px;
                  background: $primary01;
                  padding: 12px 20px;
                  border-radius: 6px;

                  @media screen and (max-width: 767px) {
                    padding: 6px 10px;
                    padding: 8px 10px;
                  }
                }
              }

              h1 {
                font-size: 39px;
                font-weight: 600;
                line-height: 48px;

                .title-text1 {
                  color: #262626;
                }

                .title-text2 {
                  background: linear-gradient(to right, #FFA96E, #FE506F);
                  background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }

              p {
                font-size: 14px;
                font-weight: 400;
                line-height: 145%;
                color: #737373;
                margin-bottom: 40px;
                padding: 0px 64.5px;
              }
            }
          }
        }
      }
    }
  }

  .text-box {
    display: block;
    text-align: center;
    margin-top: 68px;

    h6 {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.2px;
      text-align: center;
      color: rgba(64, 64, 64, 1);
    }

    h3 {
      font-size: 39px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.02em;
      text-align: center;
      color: black;
      margin-top: 12px;
      margin-bottom: 28px;

      span {
        background: linear-gradient(90deg, #ffa96e 0%, #fe506f 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 135%;
      text-align: center;
      color: rgba(115, 115, 115, 1);
      width: 62%;
      margin: auto;
    }
  }

  .cards-box {
    box-shadow: 9px 9px 50px 0px rgba(0, 0, 0, 0.06);
    margin-top: 142px;
    border-radius: 40px;
    width: 100%;
    background-image: url("../images/feature-card-img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 100px 250px;

    .about-new-card {
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15;
      padding-top: 160px;
      max-width: 270px;
      margin-inline: auto;

      .star-text {
        text-align: center;

        img {
          width: 24;
          height: 24;
          padding: 10px;
          border-radius: 50%;
          background-color: rgba(59, 130, 246, 1);
          margin-bottom: 16px;
          box-shadow: linear-gradient(156.37deg,
              #e9e9e9 2.95%,
              #d3d3d3 27.5%,
              #e3e3e3 47.14%,
              #cacaca 64.32%);
        }

        h6 {
          font-size: 23px;
          font-weight: 600;
          line-height: 32.2px;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 135%;
        text-align: center;
        color: rgba(115, 115, 115, 1);
        margin-bottom: 0px;
      }

      .circle-img {
        padding: 0;
        margin: 0;
        border: none;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: 2px solid rgba(222, 225, 230, 1);
        border-radius: 4px;
        margin-top: 16px;

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }
  }

  .cloud-platform {
    display: block;
    text-align: center;
    margin-top: 148px;
    background-image: url("../images/buil-main-backimg.svg");
    background-repeat: no-repeat;
    background-position: center 360px;
    // background-size: 1200px auto;

    .cloud-text {
      h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.2px;
        text-align: center;
        color: rgba(38, 38, 38, 1);
      }

      h1 {
        font-size: 39px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-top: 12px;
      }

      p {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 135%;
        text-align: center;
        width: 80%;
        color: rgba(115, 115, 115, 1);
      }
    }

    .cloud-card {
      margin-top: 75px;

      img {
        // width: 100%;
      }
    }
  }

  .build-box {
    box-shadow: 9px 9px 50px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin-top: 142px;
    height: 608px;
    width: 100%;
    background-image: url("../images/build-img.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 70%;
    text-align: center;

    .build-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .build-text {
        h6 {
          font-size: 23px;
          font-weight: 600;
          line-height: 32.2px;
          letter-spacing: -0.02em;
          text-align: center;
        }

        h1 {
          font-size: 48px;
          font-weight: 600;
          line-height: 56px;
          letter-spacing: -0.04em;
          text-align: center;
        }
      }

      .ilmiya-img {
        background-color: white;
        padding: 20px;
        box-shadow: 0px 1.44px 4.31px 0px rgba(59, 65, 73, 0.49) inset;
        box-shadow: 0px 5.74px 43.06px 0px rgba(195, 147, 255, 0.6);
        margin-bottom: 61px;
        margin-top: 70px;
        border-radius: 10px !important;
        background: 0 0;
        background:
          linear-gradient(45deg, white, white) padding-box,
          linear-gradient(to right, #7c4acf, #ffa96e, #ffa96e, #fe506f) border-box;
        border: 2px solid transparent;
        transition: .3s ease-in-out all;
      }

      .build-btn {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 20px;

        .build-btn1 {
          padding: 15px 30px;
          border-radius: 10px;
          border: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          color: rgba(255, 255, 255, 1);
          background: linear-gradient(90deg, #7c4acf 1.44%, #69b7fa 100%);
        }

        .build-btn2 {
          padding: 15px 30px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          border-radius: 10px !important;
          background: 0 0;
          background:
            linear-gradient(45deg, white, white) padding-box,
            linear-gradient(to right, #7c4acf, #ffa96e, #ffa96e, #fe506f) border-box;
          border: 2px solid transparent;
          transition: .3s ease-in-out all;
        }
      }
    }
  }

  // .aboutNew {
  .footer-bottom-bar {
    position: static !important;
    margin-top: 100px !important;
    // }
  }

  .main-img-wrapper {
    .ai-image-center {
      width: 100%;
      height: 520px;
      margin: auto;
      display: block;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 991px) {
    .cards-box {
      .about-new-card {
        display: block;
        padding-top: 30px !important;
        justify-content: center;
        padding-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    br {
      display: none !important;
    }

    .about-btn {
      display: flex;
      flex-wrap: wrap;

      .buttons {}
    }

    .text-box {
      p {
        width: 100% !important;
      }
    }

    .cloud-text {
      p {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 530px) {
    .cloud-card {
      margin-top: 75px;

      img {
        width: 100%;
      }
    }
  }
}

.custom-modal {
  .modal-dialog {
    max-width: 560px;

    .modal-header {
      padding: 14px;
      border: none;

      .btn-close {
        opacity: .2;
        box-shadow: none;
      }
    }

    .modal-body {
      padding: 70px 30px 40px;

      .image-wrapper {
        .header-img {
          position: absolute;
          top: -45px;
          left: 40px;
        }
      }

      .text-content-wrapper {
        .text-content-inner {
          strong {
            font-size: 19px;
            font-weight: 600;
            line-height: 26px;
            color: #262626;
            margin-bottom: 10px;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 135%;
            color: #737373;
          }
        }
      }

      .side-img-parent {
        display: flex;
        align-items: center;
        justify-content: center;

        .side-img-wrapper {
          .zero-hassle-side-img {
            height: 163px;
            width: 163px;
            object-fit: contain;
          }

          .custom-built-side-image {
            height: 159px;
            width: 159px;
            object-fit: contain;
          }

          .embedded-ai-side-image {
            height: 147.18px;
            object-fit: contain;
          }
        }
      }
    }

    .modal-footer {
      padding: 16px 20px;

      .footer-content-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;

        .footer-icon-wrapper {
          height: 36px;
          width: 36px;
          display: flex;
          align-items: center;
          border: 1px solid #dee1e6;
          padding: 5px 3px;
          border-radius: 4px;
          justify-content: center;

          img {
            height: 24px;
            width: 24px;
            object-fit: contain;
          }
        }

        strong {
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          color: #2d2f31;
        }
      }
    }
  }
}


.spaces-modal-diff {
  .modal-content {
    max-height: 466px;
    overflow-y: hidden;
  }
}

.collab-modal {
  .modal-dialog {
    max-width: 270px;

    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 0;

      .head-section {
        padding: 29px 29px 0px 29px;
        margin-bottom: 56px;

        img {
          height: 32px;
          width: 32px;
          object-fit: contain;
        }

        .text-data-section {
          margin-top: 16px;

          h2 {
            font-size: 19px;
            font-weight: 600;
            line-height: 26px;
            color: #262626;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 135%;
            color: #737373;
          }
        }
      }

      .image-container {
        margin-bottom: 88.21px;

        .col-md-4 {
          padding: 0 2px;
          margin: 0 0 5px;

          &:first-of-type {
            padding-left: 0 0 5px;
          }

          &:last-of-type {
            padding-right: 5px;
          }
        }

        .img-card {
          border: 1px solid #DEE1E6;
          box-shadow: 0px 0.39px 1.17px 0px #0000001A;
          border-radius: 3.94px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 5px;

          img {
            height: 39.04px;
            width: 39.04px;
            object-fit: contain;
            margin: 0 0 3px;
            // margin: 13.67px 21.47px 7.81px 21.47px;
          }

          span {
            font-size: 5.47px;
            font-weight: 600;
            line-height: 9.37px;
            color: #2D2F31;
            margin-top: 7.81px;
          }
        }
      }

      .table-container {

        .input-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0 6.3px 0px;
          width: 100%;

          .input-wrapper {
            display: flex;
            align-items: center;
            gap: 1.79px;
            border: 1px solid #DEE1E6;
            border-radius: 1.79px;
            padding: 0px 4.47px;
            box-shadow: 0px 0.45px 1.34px 0px #DEE1E6;
            width: 100%;

            .input-icon {
              font-size: 16px;
              color: #666;
              margin-right: 8px;
            }

            .filter-input {
              flex: 1;
              border: none;
              outline: none;
              font-size: 14px;
              color: #333;
              background: transparent;
              width: 100%;

              &::placeholder {
                font-size: 6.26px;
                font-weight: 400;
                line-height: 9.39px;
                color: #A29E9E;
              }
            }
          }
        }

        .table-wrapper {
          overflow-y: auto;
          height: 100%;
          max-height: 180px;

          &::-webkit-scrollbar {
            width: 4px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #eeeeee;
          }

          .custom-table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 6.3px;

            thead {
              tr {
                th {
                  padding: 0px 8px;

                  .th-text {
                    font-size: 8.81px;
                    font-weight: 500;
                    line-height: 15.11px;
                    color: #2D2F31;
                  }

                  &.start {
                    text-align: left;
                  }

                  &.end {
                    text-align: right;
                  }

                  .header-checkbox {
                    margin-right: 8px;
                    vertical-align: middle;
                  }
                }
              }
            }

            tbody {
              tr {
                box-shadow: 0px 0.63px 1.89px 0px #0000001A;
                border-radius: 4px 4px 4px 4px;

                &:hover {
                  background-color: #EDF4FF;
                }

                td {
                  padding: 8px;
                  border-top: 1px solid #DEE1E6;
                  border-bottom: 1px solid #DEE1E6;
                  border-left: none;

                  &:first-child {
                    border-left: 1px solid #DEE1E6;
                    border-radius: 4px 0px 0px 4px;
                  }

                  &:last-child {
                    border-right: 1px solid #DEE1E6;
                    border-radius: 0px 4px 4px 0px;
                  }

                  .grade-column {
                    font-size: 12.59px;
                    font-weight: 400;
                    line-height: 18.89px;
                    color: #2D2F31;
                  }

                  .name-column {
                    font-size: 12.59px;
                    font-weight: 600;
                    line-height: 15.11px;
                    color: #202223;
                  }

                  .first-td {
                    display: flex;
                    align-items: center;

                    .row-checkbox {
                      margin-right: 8px;
                    }

                    .avatar {
                      width: 22.66px;
                      height: 22.66px;
                      margin-right: 10px;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
        }



      }

      .build-table-container {
        border: 1px solid #DEE1E6;
        border-radius: 4.47px;
        margin-bottom: 3.39px;

        .input-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0 0;
          width: 100%;

          .input-wrapper {
            display: flex;
            align-items: center;
            gap: 1.79px;
            border: 1px solid #DEE1E6;
            border-radius: 1.79px;
            padding: 0px 4.47px;
            box-shadow: 0px 0.45px 1.34px 0px #DEE1E6;
            width: 100%;

            .input-icon {
              font-size: 16px;
              color: #666;
              margin-right: 8px;
            }

            .filter-input {
              flex: 1;
              border: none;
              outline: none;
              font-size: 14px;
              color: #333;
              background: transparent;
              width: 100%;

              &::placeholder {
                font-size: 6.26px;
                font-weight: 400;
                line-height: 9.39px;
                color: #A29E9E;
              }
            }
          }
        }

        .table-wrapper {
          width: 100%;
          overflow-x: auto;
        }

        .build-table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 6.3px;

          thead {
            tr {

              th {
                .build-table-thead-f-comp {
                  display: flex;
                  align-items: center;
                  gap: 4.47px;
                }
              }

              th {
                padding: 10px;
                text-align: left;
                font-weight: bold;


                .th-text {
                  font-size: 6.26px;
                  font-weight: 500;
                  line-height: 10.73px;
                  color: #2D2F31;
                }


                &.start {
                  text-align: left;
                }

                &.end {
                  text-align: right;
                }

                .header-checkbox {
                  margin-right: 8px;
                  vertical-align: middle;
                }
              }
            }
          }

          tbody {
            tr {
              box-shadow: 0px 0.63px 1.89px 0px #0000001A;
              border-radius: 4px 4px 4px 4px;

              &:hover {
                background-color: #EDF4FF;
              }

              td {
                .downloadIcon {
                  height: 10.73px;
                  width: 10.73px;
                  object-fit: contain;
                }
              }

              td {
                padding: 0px 8.94px;
                border-top: 1px solid #DEE1E6;
                border-bottom: 1px solid #DEE1E6;

                &:first-of-type {
                  border-left: 1px solid #DEE1E6;
                  border-radius: 4px 0 0 4px;
                }

                &:last-of-type {
                  border-right: 1px solid #DEE1E6;
                  border-radius: 0 4px 4px 0;

                }

                .success-text {
                  border-radius: 3.13px;
                  padding: 1.79px 4.47px;
                  background-color: #E2FFED;
                  font-size: 6.26px;
                  font-weight: 500;
                  line-height: 10.73px;
                  color: #22C55E;
                }

                .failed-text {
                  border-radius: 3.13px;
                  padding: 1.79px 4.47px;
                  background-color: #FFEDED;
                  font-size: 6.26px;
                  font-weight: 500;
                  line-height: 10.73px;
                  color: #EF4444;
                }

                .name-column {
                  font-size: 8.94px;
                  font-weight: 600;
                  line-height: 13.41px;
                  color: #202223;
                }

                .dollar-text {
                  font-size: 8.94px;
                  font-weight: 400;
                  line-height: 13.41px;
                  color: #2D2F31;
                }

                .first-td {
                  display: flex;
                  align-items: center;

                  .row-checkbox {
                    margin-right: 8px;
                  }

                  .avatar {
                    width: 16.09px;
                    height: 16.09px;
                    object-fit: contain;
                    margin-right: 10px;

                  }
                }

                &:last-child {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

.inter-com-chat-wrapper {
  .intercom-chat-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #7c4acf 0%, #69b7fa 100%);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: .3s ease-in-out all;
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 22;

    @media screen and (max-width: 1199px) and (min-width: 768px) {
      right: 15px;
    }

    @media screen and (max-width: 767px) {
      right: 15px;
      bottom: 20px;
      width: 36px;
      height: 36px;
    }

    &:hover {
      background: $white;
      border: 1px solid $primary01;
      transition: .3s ease-in-out all;

      img,
      svg {
        color: $primary01;
        transition: .3s ease-in-out all;
      }
    }

    img,
    svg {
      color: $white;
      width: 18px;
      height: 18px;
      object-fit: contain;
      transition: .3s ease-in-out all;

      @media screen and (max-width: 767px) {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.legal-center-detail-page {
  .main-page-wrapper {
    .inner-page-wrapper {
      .page-content-wrapper {
        height: calc(100vh - 190px);
      }
    }
  }
}