.main-section-img{
   max-width: 182px;
   width: 100%;
    height:158px;
    object-fit: contain;    
}
.main-img-section{
    text-align: center;
}
.main-title{   
    margin-top: 56px;  
}
.main-heading{
    font-weight:400 ;
    font-size:20px ;
    line-height:22px ;
    color:#3B82F6;
    text-align: center;
}
.main-header{
    font-weight:600 ;
    font-size:39px ;
    line-height:42px ;
    margin-top: 20px;
    text-align: center;

}
.main-desc{
    font-weight:400 ;
    font-size:14px ;
    line-height:12px ;
    text-align: center;
    margin-top: 20px;
    color: #737373;
}
.main-database-section{
    margin-top: 48px;
}
.database-top{
    display: flex;
    gap: 10px;
    align-items: center;
}
.database-img{
    width: 32px;
    height: 32px;
}
.database-header{
    margin: 3px 0px;
}
.header-text{
    font-size: 19px;
    line-height: 26px;
    font-weight: 600;
}
.header-desc{
    font-size: 14px;
    line-height: 12px;
    font-weight: 400;
    color: #737373;
}
.database-desc{
    margin-top: 10px;
}
// analytic container
.analytic-container{
    margin-top: 211px;

}
.left-multifactor-section{
    height: 100%;
}
.left-section{
    padding: 31px 20px;
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    // margin-top: 56px;
}
.left-multifactor{
    display: flex;
    gap: 10px;
    justify-content: center;
}
.analytic-img{
    padding: 10px 28px;
    box-shadow: 0px 0.67px 2.01px 0px #00000040;
}
.analytic-img img{
    width: 46px;
    height: 46px;
    object-fit: contain;
}
.left-multifactor-header{
    margin-top: 30px;

}
.analytic-header{
    font-size: 19px;
    line-height:26px ;
    font-weight:600 ;

}
.analytic-desc{
    font-size: 14px;
    line-height:12px ;
    font-weight:400 ;
    color: #737373;
    margin-top: 10px;

}
.left-section-bottom{
    padding: 38px 20px;
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    margin-top: 20px;

}
.holistic-img{
    display: flex;
    justify-content: center;
}
// center section
.leader-board-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    height: 100%;
    // margin-top: 56px;
}
.leader-board-heading{
    padding: 26px 19px 66px 20px;
}
.leader-card{
    padding: 10px 28px 20px 29px;
    box-shadow: 0px 10px 35px 0px #0000001A;
    text-align: center;
    max-width: 233px;
    width: 100%;
    height: 270px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    // left: 85px;
    bottom: 14px;
    background-color: #FFFFFF;
}
.vector-img{
    max-width: 402px;
    width: 100%;
    height: 77px;
}
.leader-mac-img{
    width: 79px;
    height: 46px;
    object-fit: contain;
    padding-bottom: 10px;
}
.leader-board-bottom{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.leader-card-text{
    font-size: 14px;
    font-weight: 500;
    line-height:20px ;
    text-align: center;
    color: #737373;
    padding-bottom: 18px;
}
.border-bottom{
    border-bottom:1px solid #000000 ;
    
}
.leader-card-images{
    max-width: 73px;
    // height: 221px;
    margin-top: 10px;
}

// right section
.right-report-section{
    height: 100%;
}
.report-card{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    padding: 126px 20px 34px 20px;
    // margin-top: 56px;

}
.authentication-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    padding: 39px 20px 34px 20px;
    margin-top: 20px;

}
.authentication-img{
    position: relative;
    display: flex;
    justify-content: center;
}
.authentication-lock{
    position: absolute;
    bottom: 35px;
    max-width: 58px;
    width: 100%;
    object-fit: contain;
}
.authentication-bottom-img{
    max-width:281px ;
    width: 100%;
    object-fit: contain;
}
.authentication-header{
    margin-top: 16px;
}
// botttom left section
.bottom-left-authentication-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.bottom-left-authentication-heading{
    padding: 13px 20px 177px 20px;
}
.browse-img{
    padding: 25px;
    border-radius: 32px;
    box-shadow: 0px 1.61px 4.83px 0px #00000040;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 170px;
}
.browse-img img{
    width: 110px;
    height: 110px;
    object-fit: contain;

}
.bottom-left-authentication-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // bottom: 0px;

}
.elipse-heading{
    position: absolute;
    top: 114px;
}
.elipse-title{
    font-size: 23px;
    font-weight:600 ;
    line-height: 32px;
    color: #3B82F6;
    margin: 0px;

}
.elipse-text{
    font-size: 14px;
    font-weight:400 ;
    line-height: 12px;
    color: #3B82F6;
    margin-top: 3px;

}
.elipse-img{
    max-width: 420px;
    width: 100%;
    object-fit: contain;

}

// bottom center section
.multifactor-bottom-section{
    height: 100%;

}
.bottom-center-multifactor-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
}
.bottom-center-multifactor-img{
    display: flex;
    justify-content: space-between;
}
.multifactor-img{
    display: flex;
    flex-direction: column;
}
.multifactor-left-img{
    padding: 28px 0px 0px 31px;
    position: relative;
}
.multifactor-left-img img{
    width: 32px;
    height: 32px;
    object-fit: contain;
}
.multifactor-center-img{
    position: relative;
}
.multifactor-vector{
    width: 79px;
    height: 76px;
}
.layer-img{
    width: 64px;
    height: 64px;
    object-fit: contain;
    position: absolute;
    left: 8px;
    top: 30px;
}
.mulitfactor-frame{
    position: absolute;
    left: 61px;
    top: 52px;
}

.bottom-center-multifactor-heading{
    padding: 60px 20px 32px 20px;
}
.multifactor-right-img{
    padding-right: 23px;
    padding-top: 28px;
    position: relative;
}
.multifactor-right-img img{
    width: 32px;
    height: 32px;
    object-fit: contain;
}
.multifactor-right-frame{
    position: absolute;
    right: 52px;
    top: 53px;

}


// bottom-right-section
.bottom-right-stream-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    height: 100%;
}
.stream-section-heading{
    padding: 21px 20px 50px 20px;

}
.stream-profile-section{
    display: flex;
    padding: 4px 0px 4px 9px;
    margin-left: 61px;
    align-items: center;
    gap: 13px;
    border: 0.67px solid #DEE1E6;
}
.stream-profile-section img{
    width: 24px;
    height: 24px;
    object-fit: contain;
}
.stream-profile-title{
    font-size: 13px;
    font-weight:600 ;
    line-height:18px ;
    margin: 0px;
}
.stream-bottom-img{
    position: relative;
    left: 76px;
}
.stream-cirlce{
    position: absolute;
    left: -17px;
    top: 32px;
}
.stream-cirlce2{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 20px;
    top: 70px;
    gap: 30px;
}



// admin container
.admin-container{
    margin-top: 128px;
}
.support-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    height: 100%;
}
.support-img{
    display: flex;
    justify-content: center;
}
.support-img img{
    width: 32px;
    height: 32px;
}
.support-heading{
    padding: 55px 30px 0px 31px;
}
.support-header{
    text-align: center;
    margin-top: 16px;
}
.support-desc{
    text-align: center;

}
.support-alex-section1{
    padding: 28px 35px 29px 35px;
    border: 1px solid #3B82F633;
    border-top-right-radius: 8px;
    border-bottom-right-radius:8px ;
    width: 129px;
    min-height: 72px;
}
.support-alex-section2{
    padding: 28px 35px 29px 35px;
    border: 1px solid #3B82F633;
    border-top-left-radius: 8px;
    border-bottom-left-radius:8px ;
    width: 129px;
    min-height: 72px;

}
.alex-section{
    margin-top: 45px;
}
.support-alex{
    display: flex;
    justify-content: space-between;
}
// global ai
.global-ai-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    padding: 36px 33px 83px 41px;
    height: 100%;
    display: flex;
    // align-items: center;
}
.global-heading{
   margin-top: 19px;

}
.global-left-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.global-right-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 50px;
}
.global-right-img{
    position: absolute;
}
.ai-img{
    display: flex;
    gap: 8px;
}
.ai-img img{
    width: 16px;
    height: 16px;
    object-fit: contain;
}
.ai-title{
    font-size: 14px;
    font-weight:400 ;
    line-height: 12px;
    color: #262626;

}
.admin-database-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    padding: 36px 41px 65px 41px;
    margin-top: 32px;
    display: flex;
    max-width: 560px;
    height: 100%;
    align-items: center;

}
.admin-database-header{
    margin-top: 19px;

}
.admin-database-heading{
    display: flex;
    gap: 7px;
}
.admin-database-heading img{
    width: 24px;
    height: 24px;
}
.admin-database-bottom{
    margin-top: 153px;
}
.admin-database-right-section{
    margin-left: 25px;
    position: relative;
}
.database-right-img{
    position: absolute;
    left: 94px;
    top: 34px;
}

// label section
.admin-label-section{
    border: 1px solid #DEE1E6;
    box-shadow: 0px 1px 8px 0px #0000000F;
    border-radius: 10px;
    padding: 38px 31px 320px 30px;
    max-width: 270px;
    margin-top: 32px;
    height: 100%;

}
.admin-label-img{
    display: flex;
    justify-content: center;
}
.admin-label-img img{
    width: 32px;
    height: 32px;
}
.admin-label-header{
    text-align: center;
    margin-top: 16px;
}
.admin-label-title{
    text-align: center;
}

// engage section
.engage-container{
    margin-top: 128px;
}
.engage-space-left-section{
    position: relative;
}
.engage-left-top{
    display: flex;
    max-width: 545px;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #3B82F633;
    border-radius: 16px;
    padding-bottom: 20px;
}

.engage-left, .engage-right{
    max-width: 119px;
    width: 100%;
    // height: 281px;
    // margin-top: 38px;
    object-fit: contain;
}
.engage-form-section{
    max-width: 273px;
    min-height: 310px;
    width: 100%;
    padding: 29px 35px 0px 35px;
    margin-inline: 16px;
    border-radius: 5px;
    margin-top: 24px;
    box-shadow: 0px 4.97px 14.9px 0px #0000001A;
}
.engage-form-title{
    font-size:15px ;
    font-weight:400 ;
    line-height:23px ;
}
.engage-input{
    display: flex;
    padding: 5px 62px;
    align-items: center;
    gap: 10px;
    border: 0.5px solid #DEE1E6;
    margin-top: 24px;

}
.engage-input img{
    width: 11px;
    height: 11px;
}
.engage-input input{
    border: none;
    outline: none;
    width: 100%
    
}
.engage-input input::placeholder {
    font-size: 6px; 
    font-weight: 400;
    line-height: 8px;
    color: #A29E9E;
    
  }
  .engage-form-desc{
    font-size:6px ;
    font-weight:400 ;
    line-height:10px ;
    color: #A29E9E;
    text-align: center;
    padding: 16px 6px 0px 6px;
  }
  .engage-border{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .line {
    flex: 1;
    border: none;
    border-bottom: 1px solid #DEE1E6; 
  }
  .engage-social-section{
    display: flex;
    gap: 10px;
    margin-top: 12px;
  }
  .engage-social-img{
    padding: 7px 21px;
    border-radius: 4px;
    border: 1px solid #DEE1E6;
  }
  .engage-social-img img{
    width: 19px;
    height: 19px;
  }
  .engage-login{
    font-size:6px ;
    font-weight:400 ;
    line-height:8px ;
    margin-top: 25px;

  }
  .engage-login a{
    font-size:6px ;
    font-weight:400 ;
    line-height:8px ; 
    text-decoration: none;

  }
  .engage-login  img{
    width: 9px;
    height: 9px;
    object-fit: contain;
  }
  .engage-policy{
    font-size:6px ;
    font-weight:400 ;
    line-height:11px ;
    text-align: center; 

  }
  .engage-policy a{
    font-size:6px ;
    font-weight:400 ;
    line-height:11px ;
    text-align: center;
    text-decoration: none; 

  }
  .engage-list ul{
    display: flex;
    list-style-type: none;
    gap: 5px;
    margin: auto;
    padding: 12px 60px;
  }
  .engage-list ul li a{
    text-decoration: none;
    font-size:6px ;
    font-weight:400 ;
    line-height:8px ;
    color: #A29E9E;


  }
  .engage-left-header{
    font-size:24px ;
    font-weight:600 ;
    line-height:31px ;
    color: #000000;
    margin-top: 25px;

  }
  .engage-left-desc{
    font-size:16px ;
    font-weight:400 ;
    line-height:23px ;
    color: #414141;
    margin-top: 25px;

  }
  .engage-left-bottom-img{
    position: absolute;
    bottom: 11px;
    left: 10px;
   
  }
  .engage-img-section{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

  }
  .engage-left-img{
    padding: 10px 14px;
    border-radius: 13px;
    background-color: #FFFFFF;
    box-shadow: 0px 0.67px 2.01px 0px #00000040;
  }
  .engage-left-img img{
    width: 46px;
    height: 46px;
    object-fit: contain;
  }
  .engage-space-section{
    display: flex;
    margin-top: 57px;
  }
  .engage-right-header{
    font-size:24px ;
    font-weight:600 ;
    line-height:31px ;
    color: #000000;

  }
  .engage-right-desc{
    font-size:16px ;
    font-weight:400 ;
    line-height:23px ;
    color: #414141;
    padding-top: 25px;

  }

//   management section
.management-container{
    margin-top: 128px;
}
.management-title{
    font-size:16px ;
    font-weight:400 ;
    line-height: 23px;
}
.management-header{
    font-size:32px ;
    font-weight:600 ;
    line-height: 46px;
    margin: 6px 0px 20px 0px;

}
.management-desc{
    font-size:18px ;
    font-weight:400 ;
    line-height: 26px;

}
.management-border{
    border: 1px solid #3B82F633;
    border-radius: 16px;
}

.management-title{
    font-size:14px ;
    font-weight:500 ;
    line-height:20px ;
}
.management-desc{
    font-size:14px ;
    font-weight:400 ;
    line-height:20px ;

}
.management-bottom-section{
    margin: 46px 0px 128px 0px;
}
.custom-section{
    height: 100%;
}
.custom-section-heading{
    padding: 29px 37px 36px 38px;
}
.custom-section-img{
    margin: 48px 0px 0px 24px;
}
.custom-img-sections{
    display: flex;
    gap: 8px;
    margin-bottom: 8px;

}
.custom-img-sections img{
    width:77px ;
    height: 79px;
    object-fit: contain;
}
.img-bg-color{
    background-color: #267FE599;
}
.img-section{
    width: 84px;
    height: 87px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0px 8px ;
}
.custom-section-bottom-list{
    list-style-type: none;
    display: flex;
    gap: 20px;
}
.management-list-outer{
    border: 1px solid #7C4ACF;
    border-radius: 999px;
    padding: 8px 15px 9px 15px;
    margin-top: -10px;
}


// 2nd col
.classes-img-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
    position: relative;

}
.classes-img img{
    width:30px ;
    height: 30px;
    object-fit: contain;
    margin-right: 12px;
}
.classes-arrow{
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%);
}
.classes-btn{
    background-color: #3B82F6;
    border-radius: 999px;
    padding: 5px 11px 6px 11px;
    border: none;

    font-size: 10px;
    font-weight:400 ;
    line-height: 14px;
    color: #FFFFFF;
    position: absolute;
    top: 75px;
}
.classes-btn img{
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 7px;
}
.classes-section-heading{
    padding:100px 65px 22px 37px;
}
.invitation-section{
    margin-top: 20px;
}
.invitation-btn{
    padding: 7px 15px;
    background-color: #3B82F6;
    font-size: 14px;
    font-weight:400 ;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    border-radius: 999px;
    position: absolute;
    top: 70px;
}
.invitation-btn img{
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 6px;

}
.invitation-arrow{
    position: absolute;
    left: 220px;
    top: 89px;
}
.invitation-top{
    margin: 0px 57px 0px 60px;
    position: relative;
}
.invitation-heading{
    padding: 56px 67px 26px 35px;
}

// 3rd col
.organizational-section{
    padding: 29px 64px 32px 38px;
    height: 100%;
}
.organizational-btn{
    background-color: #3B82F6;
    font-size: 10px;
    font-weight:400 ;
    line-height: 14px;
    color: #FFFFFF;
    padding: 7px 10px;
    border: none;
    border-radius: 4px;
    margin-top: 43px;
    margin-bottom: 16px;
}
.organizational-btn-section{
    display: flex;
    justify-content: center;
    align-items: center;
}
.organizational-btn img{
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 20px;
}


// responsive design
@media (min-width: 768px) and (max-width: 1024px) {
    .main-header{
        font-size: 37px;
        line-height: 40px;
    }
    .main-desc{
        font-size: 12px;
    }
    .main-heading{
        font-size: 18px;
    }
   .header-text{
    font-size:16px ;
    line-height: 25px;
   }
   .header-desc{
    font-size: 12px;
   }
   .analytic-container{
    margin-top: 100px;
   }
   .analytic-img{
    padding: 5px 7px;
   }
   .analytic-header{
    font-size: 16px;
    line-height: 25px;
   }
   .analytic-desc{
    font-size: 12px;
   }
   .authentication-lock{
    bottom: 9px;
   }
   .elipse-heading{
    top: 60px;
   }
   .elipse-title{
    font-size: 20px;
   }
   .elipse-text{
    font-size: 12px;
   }
   .browse-img{
    padding:10px ;
    bottom:80px;
   }
   .multifactor-left-img{
    padding-left: 16px;
   }
   .mulitfactor-frame {
    left: 41px;
    top: 57px;
   }
   .multifactor-right-img{
    padding-right: 11px;
   }
   .multifactor-right-frame{
    right: 38px;
    top: 57px;
   }
   .stream-profile-title{
    font-size: 8px;
   }
   .admin-database-section{
    width: 100%;
    max-width: none;
   }
   .admin-label-section{
    width: 100%;
    max-width: none;

   }
   .engage-space-section{
    flex-direction: column;
   }
   .engage-space-right-section{
    margin-top: 30px;
   }
   .global-ai-section{
    flex-direction: column;
   }
   .ai-img{
    margin-top: 20px;
   }
   .management-desc{
    font-size: 12px;
   }
   .organizational-section{
    margin-top: 20px;
   }
   .invitation-btn{
    font-size: 12px;
   }
   .admin-database-bottom {
    margin-top: 90px;
   }

  }
  @media (min-width: 375px) and (max-width: 812px) {
    .main-header{
        font-size: 28px;
        line-height: 38px;
    }
    .main-desc{
        font-size: 12px;
    }
    br {
        display: none;
      }
    .main-heading{
        font-size: 18px;
    }
    .admin-database-section {
        flex-direction: column;
    }
    .admin-label-section{
        width: 100%;
        max-width: none;
    }
    .global-ai-section{
        flex-direction: column;
       }
       .ai-img{
        margin-top: 20px;
       }
    .engage-space-section {
        flex-direction: column;
    }
    .engage-space-right-section{
        margin-top: 30px;
       }
       .classes-section{

        margin-top: 20px;
       }
       .organizational-section{
        margin-top: 20px;
       }
       .management-header{
        font-size: 22px;
        line-height: 35px;
       }
  }

#side-bar-main-wrapper {
    &.active {
        #side-bar-wrapper {
            position: fixed;
            left: 0;
            z-index: 999;
            transition: 0.5s ease-in-out all;
        }
    }

    &.mobile {
        #side-bar-wrapper {
            left: 0;
        }
    }
    #side-bar-wrapper {
        border-right: 1px solid #DEE1E6;
        max-width: 332px;
        width: 100%;
        position: absolute;
        height: calc(100vh - 130px);
        top: 0;
        left: -100%;
        background: #ffffff;
        transition: 0.5s ease-in-out all;
        // z-index: 9999;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ffffff !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #468ee9;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        @media screen and (max-width: 991px) {
            position: fixed;
            height: 100vh;
            z-index: 9999;
        }

        @media screen and (max-width: 767px) {
            max-width: 300px !important;
            position: fixed;
        }

        @media screen and (max-width: 991px) {
            left: -100%;
            transition: 0.5s ease-in-out all;
        }

        .title-wrapper {
            display: flex;
            align-items: center;
            gap: 14px;
            padding: 12px 22px 12px 24px;
            @media screen and (max-width: 767px) {
                padding: 12px 15px;
            }
            .img-wrapper {
                display: flex;
                width: 36px;
                height: 36px;
                // padding: 9px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: #ffffff;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
                object-fit: cover;
            }

            .text-wrapper {
                transition: 0.3s ease-in-out all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                strong {
                    font-size: 14px !important;
                    font-weight: 600;
                    line-height: 20px;

                    @media screen {
                        font-size: 12px;
                    }
                }
            }
        }
        ul.side-menu-wrapper {
            border-top: 1px solid #dee1e6;
            // border-bottom: 1px solid #dee1e6;
            padding: 12px 20px 15px 23px;
            height: calc(100% - 66px);
            overflow-y: scroll;
            margin: 0;
            transition: 0.3s ease-in-out all;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
                padding: 12px 15px 15px 23px;
            }
            @media screen and (max-width: 767px) {
                padding: 12px 15px 15px 15px;
            }

            &::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #ffffff !important;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #468ee9;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            li {
                display: block;
                height: 63px;
                transition: 0.3s ease-in-out all;

                &:not(&:last-of-type) {
                    margin: 0 0 10px 0;
                }

                a {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 12px 10px;
                    border-radius: 8px;
                    border: 1px solid #dee1e6;
                    background: #ffffff;
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
                    height: 64px;
                    cursor: pointer;

                    &.active {
                        background: #fff !important;
                        border: 1px solid #3b82f6 !important;
                        //box-shadow: 0px 0px 10px 0px #4286F54D;
                        span {
                            color: black;
                        }
                    }

                    .CircularProgressbar {
                        height: 25px !important;
                        width: 25px !important;
                        position: absolute;
                        right: 10px;

                        .CircularProgressbar-text {
                            dominant-baseline: middle !important;
                            text-anchor: middle !important;
                        }
                    }


                    .icon-wrapper.box {
                        background: #fff;
                    }

                    span {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #202223;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: 0.3s ease-in-out all;
                        position: relative;
                    }
                }
            }
        }
        .side-bar-toggle-wrapper {
            padding: 22px 20px;
            //text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            a {
                display: flex;
                align-items: center;
                font-weight: 600;
                vertical-align: middle;
                cursor: pointer;
                &.blue{
                    color: #3b82f6 !important;
                }
                img, svg{
                    margin: 0 10px 0 0;
                    width : 16px;
                    height : 16px;
                }
            }
        }
    }
}